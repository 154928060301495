import * as Yup from 'yup';

export const formValidationSchema = Yup.object().shape({
  tgl_penerima_petty_cash: Yup.string().required(
    'Pilih Tanggal Penerima Petty Cash'
  ),
  id_pekerja: Yup.string().required('Pilih Pekerja'),
  id_proyek: Yup.string().required('Pilih Proyek'),
  keterangan: Yup.string().required('Masukkan Keterangan'),
});
