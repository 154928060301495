import React from 'react';
import { Card } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { InfoItemVertical, Input, ActionButton, Alert } from 'components';

export const SectionDataAkun = ({ action, onSubmit, submitLoading, alert }) => {
  const { values, errors, touched, handleChange } = useFormikContext();

  if (action === 'READ') {
    return (
      <Card>
        <Card.Header>
          <b>Data Akun</b>
        </Card.Header>
        <Card.Body>
          <InfoItemVertical label="Username" text={values.username} />
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card>
      <Card.Header>
        <b>Data Akun</b>
      </Card.Header>
      <Card.Body>
        <Alert show={alert?.show} variant={alert?.variant} text={alert?.text} />
        <Input
          autoComplete={false}
          type="text"
          label="Username"
          name="username"
          placeholder="Masukan username"
          value={values.username}
          error={errors.username && touched.username && true}
          errorText={!!(errors.username && touched.username) && errors.username}
          onChange={handleChange}
        />
        <Input
          autoComplete={false}
          type="password"
          label="Ubah Password"
          placeholder="Kosongkan jika tidak diubah"
          name="password"
          value={values.password}
          error={errors.password && touched.password && true}
          errorText={!!(errors.password && touched.password) && errors.password}
          onChange={handleChange}
        />
        <Input
          autoComplete={false}
          type="password"
          label="Konfirmasi Password"
          name="password_confirmation"
          value={values.password_confirmation}
          error={
            errors.password_confirmation &&
            touched.password_confirmation &&
            true
          }
          errorText={
            !!(errors.password_confirmation && touched.password_confirmation) &&
            errors.password_confirmation
          }
          onChange={handleChange}
        />

        {action === 'UPDATE' && (
          <div className="text-right mt-4">
            <ActionButton
              variant="success"
              text="Ubah Data Akun"
              onClick={onSubmit}
              loading={submitLoading}
            />
          </div>
        )}
      </Card.Body>
    </Card>
  );
};
