import Services from '../../../services';

class GradeApi {
  get() {
    return Services.get('/hrda/grade');
  }

  getPage(page, dataLength, key) {
    return Services.get(
      `/hrda/grade/page/?per_page=${dataLength}&page=${page}&q=${key}`
    );
  }

  search(key) {
    return Services.get('/hrda/grade/page/?per_page=10&page=1&q=' + key);
  }

  getDropdown() {
    return Services.get('/hrda/grade/dropdown');
  }

  show(value) {
    return Services.put('/hrda/grade/show', value);
  }

  hide(value) {
    return Services.put('/hrda/grade/hide', value);
  }

  create(value) {
    return Services.post('/hrda/grade', value);
  }

  update(value) {
    return Services.put('/hrda/grade', value);
  }

  delete(value) {
    return Services.post('/hrda/grade/delete', value);
  }
}

export default new GradeApi();
