import Services from 'services';

const Mock = {
  get: (url, params) =>
    new Promise((res, rej) =>
      setTimeout(() => {
        switch (url) {
          case '/v1/example/page':
            res({
              data: [{}],
            });

          case '/v1/example/single':
            res({
              data: {
                tanggal: '2020-01-01',
                keterangan: '',
                karyawan: Array.from({ length: 2 }).map((_, i) => ({
                  id: i + 1,
                  nama: `User ${i + 1}`,
                })),
                detail: Array.from({ length: 50 }).map((_, i) => ({
                  barang: `Barang ${i}`,
                  qty: i + 10,
                  harga: i + 1000,
                })),
              },
            });

          case '/v1/example/dropdown':
            res({
              data: Array.from({ length: 50 }).map((_, i) => ({
                id: i,
                nama: `User ${i}`,
              })),
            });
          default:
            res({});
        }
      }, 500)
    ),
  post: (url, params) =>
    new Promise((res, rej) => setTimeout(() => res('berhasil'), 500)),
  patch: (url, params) =>
    new Promise((res, rej) => setTimeout(() => res('berhasil'), 500)),
  put: (url, params) =>
    new Promise((res, rej) => setTimeout(() => res('berhasil'), 500)),
  delete: (url, params) =>
    new Promise((res, rej) => setTimeout(() => res('berhasil'), 500)),
};

class PenerimaPettyCashApi {
  async getList(params) {
    const fetch = await Services('/hrda/penerima_petty_cash/page', { params });
    return fetch.data;
  }

  getSingle(params) {
    return Services('/hrda/penerima_petty_cash/single', { params });
  }

  async getDropdownProyek() {
    const fetch = await Services.get('/hrda/penerima_petty_cash/d_proyek');
    return fetch?.data?.data?.map((val) => ({
      label: val.nama_proyek,
      value: val.id_proyek,
    }));
  }

  async getDropdownKaryawan() {
    const fetch = await Services.get('/hrda/karyawan/dropdown');
    return fetch?.data?.data?.map((val) => ({
      label: `${val.nama_karyawan} - ${val.nama_jabatan} - ${val.nama_unit_organisasi}`,
      value: val.id_karyawan,
      nama_karyawan: val.nama_karyawan,
      nama_jabatan_penerima: val.nama_jabatan,
      nama_unit_organisasi: val.nama_unit_organisasi,
    }));
  }

  showHide(data) {
    return Services.put('/hrda/penerima_petty_cash/toggle', data);
  }

  generateNumber(params) {
    return Services.get('/hrda/penerima_petty_cash/no_baru', { params });
  }

  create(data) {
    return Services.post('/hrda/penerima_petty_cash', data);
  }

  update(data) {
    return Services.put('/hrda/penerima_petty_cash', data);
  }
}

export default new PenerimaPettyCashApi();
