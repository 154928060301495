import Services from 'services';

class HakAksesDivisiApi {
  hak_akses_karyawan(params) {
    return Services.get('/hrda/hak_akses/hak_akses_karyawan', { params });
  }
  get(id, kode) {
    return Services.get(
      `/hrda/hak_akses/karyawan/?id_karyawan=${id}&tipe_hak_akses=menu&kode_hak_akses_parent=${kode}`
    );
  }
  getDropdown() {
    return Services.get('/hrda/karyawan/');
  }
  getDivisi() {
    return Services.get('/hrda/hak_akses/?tipe_hak_akses=divisi');
  }
  create(value) {
    return Services.post('/hrda/hak_akses/karyawan/', value);
  }
  delete(params) {
    return Services.post('/hrda/hak_akses/delete', params);
  }
}

export default new HakAksesDivisiApi();
