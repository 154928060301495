import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { debounce, isArray } from 'lodash';
import {
  CRUDLayout,
  InputSearch,
  Alert,
  Table,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  ReadButton,
  UpdateButton,
  DataStatus,
  Pagination,
  CreateButton,
  DeleteButton,
  ActionButton,
} from 'components';
import { TableNumber } from 'utilities';
import { PekerjaApi } from 'api';

const ModalDelete = ({ show, onHide, onSubmit, data, loading }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <span className="text-danger">Hapus Data Keluarga</span>
      </Modal.Header>
      <Modal.Body className="text-center">
        <h5>
          <span>Hapus data dengan nama: </span>
          <br />
          <b>{data?.nama_karyawan}</b>
        </h5>
        <small className="text-danger">
          Data yang dihapus tidak dapat dikembalikan!
        </small>
        <div className="d-flex justify-content-center mt-3">
          <ActionButton
            variant="outline-secondary"
            className="m-1"
            text="Batal"
            onClick={onHide}
          />
          <ActionButton
            variant="danger"
            className="m-1"
            text="Hapus Data"
            loading={loading}
            onClick={onSubmit}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

const PekerjaList = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: true,
    error: true,
  });
  const [alert, setAlert] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [modal, setModal] = useState({
    show: false,
    loading: false,
    data: {},
  });
  const [paginationConfig, setPaginationConfig] = useState({
    q: undefined,
    page: location?.state?.pelamar_kerja?.filter?.page ?? '1',
    dataLength: location?.state?.pelamar_kerja?.filter?.dataLength ?? '10',
    totalPage: location?.state?.pelamar_kerja?.filter?.totalPage ?? '1',
    dataCount: location?.state?.pelamar_kerja?.filter?.dataCount ?? '0',
  });

  const getData = () => {
    setData([]);
    setFetchingStatus({
      loading: true,
      error: true,
    });

    PekerjaApi.getPage({
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
      q: paginationConfig.q,
    })
      .then((res) => {
        setData(res?.data?.data ?? []);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: res?.data?.data_count,
          totalPage: res?.data?.total_page,
        });
        setFetchingStatus({
          loading: false,
          error: false,
        });
      })
      .catch(() => {
        setData([]);
        setFetchingStatus({
          loading: false,
          error: true,
        });
      });
  };

  const searchHandler = (e) => {
    setPaginationConfig({
      ...paginationConfig,
      q: e.target.value,
      page: 1,
    });
  };

  const deleteDataHandler = () => {
    setModal({
      ...modal,
      loading: true,
    });

    PekerjaApi.delete({ id_karyawan: modal?.data?.id_karyawan })
      .then(() => {
        setAlert({
          show: true,
          variant: 'primary',
          text: 'Data berhasil dihapus!',
        });
      })
      .catch((err) =>
        setAlert({
          show: true,
          variant: 'danger',
          text: isArray(err?.response?.data?.error)
            ? err?.response?.data?.error?.map((val) => <li>{val}</li>)
            : err?.response?.data?.message,
        })
      )
      .finally(() => {
        getData();
        setModal({
          show: false,
          data: {},
          loading: false,
        });
      });
  };

  useEffect(() => {
    setNavbarTitle('Pekerja');
    setAlert({
      show: !!history?.location?.state?.alert?.show,
      variant: history?.location?.state?.alert?.variant,
      text: history?.location?.state?.alert?.text,
    });
  }, []);

  useEffect(() => {
    getData();
  }, [paginationConfig.q, paginationConfig.page, paginationConfig.dataLength]);

  return (
    <CRUDLayout>
      {/* Head */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-0">
            <InputSearch onChange={debounce(searchHandler, 800)} />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() =>
              history.push('/human-resource/master/pekerja/tambah')
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <div className="mt-2">
        <Alert show={alert.show} variant={alert.variant} text={alert.text} />
      </div>

      {/* Content */}
      {fetchingStatus.loading || fetchingStatus.error ? (
        <DataStatus
          loading={fetchingStatus.loading}
          text={fetchingStatus.loading ? 'Memuat . . .' : 'Data gagal dimuat'}
        />
      ) : (
        <>
          <Table>
            <THead>
              <Tr>
                <ThFixed>No.</ThFixed>
                <ThFixed>Aksi</ThFixed>
                <Th style={{ minWidth: 100 }} className="p-1">
                  No. Pegawai
                </Th>
                <Th style={{ minWidth: 100 }} className="p-1">
                  Username
                </Th>
                <Th style={{ minWidth: 300 }} className="p-1">
                  Nama Pegawai
                </Th>
                <Th style={{ width: 150 }} className="p-1">
                  Jabatan
                </Th>
                <Th style={{ width: 150 }} className="p-1">
                  Lokasi Kerja
                </Th>
              </Tr>
            </THead>
            <TBody>
              {data && data?.length > 0 ? (
                data.map((e, i) => (
                  <Tr key={i}>
                    <TdFixed>
                      {TableNumber(
                        paginationConfig.page,
                        paginationConfig.dataLength,
                        i
                      )}
                    </TdFixed>
                    <TdFixed>
                      <div className="d-flex">
                        <ReadButton
                          size="sm"
                          onClick={() =>
                            history.push(
                              '/human-resource/master/pekerja/detail/' +
                                e?.id_karyawan
                            )
                          }
                        />
                        <UpdateButton
                          size="sm"
                          onClick={() =>
                            history.push(
                              '/human-resource/master/pekerja/ubah/' +
                                e?.id_karyawan
                            )
                          }
                        />
                        <DeleteButton
                          size="sm"
                          onClick={() =>
                            setModal({ show: true, data: e, loading: false })
                          }
                        />
                      </div>
                    </TdFixed>
                    <Td>{e?.no_karyawan ?? '-'} </Td>
                    <Td>{e?.username ?? '-'}</Td>
                    <Td>{e?.nama_karyawan ?? '-'}</Td>
                    <Td>{e?.nama_jabatan ?? '-'}</Td>
                    <Td>{e?.nama_lokasi_kerja ?? '-'}</Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={11}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </Tr>
              )}
            </TBody>
          </Table>
          <Pagination
            dataLength={paginationConfig?.dataLength}
            dataNumber={
              paginationConfig?.page * paginationConfig?.dataLength -
              paginationConfig?.dataLength +
              1
            }
            dataPage={
              paginationConfig?.dataCount < paginationConfig?.dataLength
                ? paginationConfig?.dataCount
                : paginationConfig?.page * paginationConfig?.dataLength
            }
            dataCount={paginationConfig?.dataCount}
            currentPage={paginationConfig?.page}
            totalPage={paginationConfig?.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        </>
      )}
      <ModalDelete
        show={modal.show}
        loading={modal.loading}
        data={modal.data}
        onHide={() => setModal({ data: {}, show: false, loading: false })}
        onSubmit={deleteDataHandler}
      />
    </CRUDLayout>
  );
};

export default PekerjaList;
