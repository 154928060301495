export const formInitialValues = (data = {}, action = 'CREATE') => {
  const isCreate = action === 'CREATE';
  const TODAY = new Date();

  return Object({
    id_penerima_petty_cash: isCreate ? null : data?.id_penerima_petty_cash,
    tgl_penerima_petty_cash: isCreate ? TODAY : data?.tgl_penerima_petty_cash,
    no_penerima_petty_cash: isCreate ? '' : data?.no_penerima_petty_cash,
    id_pekerja: isCreate ? '' : data?.id_pekerja,
    id_proyek: isCreate ? '' : data?.id_proyek,
    keterangan: isCreate ? '' : data?.keterangan,

    nama_karyawan: data?.nama_karyawan ?? '',
    nama_jabatan_penerima: data?.nama_jabatan_penerima ?? '',
    nama_unit_organisasi: data?.nama_unit_organisasi ?? '',
    nama_proyek: data?.nama_proyek ?? '',
  });
};
