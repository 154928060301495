import React, { useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import {
  ActionButton,
  Alert,
  DatePicker,
  InfoItemVertical,
  Input,
  Select,
  SelectSearch,
} from 'components';
import { DateConvert } from 'utilities';

export const SectionDataPribadi = ({
  action,
  onSubmit,
  submitLoading,
  alert,
}) => {
  const { values, errors, touched, handleChange, setFieldValue } =
    useFormikContext();
  const dataJenisKelamin = [
    { value: 'L', label: 'Laki-laki' },
    { value: 'P', label: 'Perempuan' },
  ];
  const dataAgama = [
    { value: 'IS', label: 'Islam' },
    { value: 'KP', label: 'Kristen Protestan' },
    { value: 'KK', label: 'Kristen Katholik' },
    { value: 'HD', label: 'Hindu' },
    { value: 'BD', label: 'Budha' },
    { value: 'KO', label: 'Khonghucu' },
  ];
  const dataStatusPerkawinan = [
    { value: 'BK', label: 'Belum Kawin' },
    { value: 'KW', label: 'Kawin' },
    { value: 'CH', label: 'Cerai Hidup' },
    { value: 'CM', label: 'Cerai Mati' },
  ];

  if (action === 'READ') {
    return (
      <Card>
        <Card.Header>
          <b>Data Pribadi</b>
        </Card.Header>
        <Card.Body>
          <InfoItemVertical
            label="Nama Pegawai"
            text={values?.nama_karyawan ?? '-'}
          />
          <InfoItemVertical label="No. KTP" text={values?.no_ktp ?? '-'} />
          <Row>
            <Col lg>
              <InfoItemVertical
                label="Tempat Lahir"
                text={values?.tempat_lahir ?? '-'}
              />
            </Col>
            <Col lg>
              <InfoItemVertical
                label="Tanggal Lahir"
                text={
                  values?.tgl_lahir
                    ? DateConvert(new Date(values.tgl_lahir)).detail
                    : '-'
                }
              />
            </Col>
          </Row>
          <Row>
            <Col lg>
              <InfoItemVertical
                label="Jenis Kelamin"
                text={
                  dataJenisKelamin?.find(
                    (val) => val.value === values.jenis_kelamin
                  )?.label
                }
              />
            </Col>
            <Col lg>
              <InfoItemVertical
                label="Agama"
                text={
                  dataAgama?.find((val) => val.value === values.agama)?.label
                }
              />
            </Col>
          </Row>
          <InfoItemVertical
            label="Status Perkawinan"
            text={
              dataStatusPerkawinan?.find(
                (val) => val.value === values.status_perkawinan
              )?.label
            }
          />
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card>
      <Card.Header>
        <b>Data Pribadi</b>
      </Card.Header>
      <Card.Body>
        <Alert show={alert?.show} variant={alert?.variant} text={alert?.text} />
        <Input
          type="text"
          label="Nama Pegawai"
          placeholder="Masukan nama pegawai"
          name="nama_karyawan"
          value={values.nama_karyawan}
          error={errors.nama_karyawan && touched.nama_karyawan && true}
          errorText={
            errors.nama_karyawan &&
            touched.nama_karyawan &&
            errors.nama_karyawan
          }
          onChange={handleChange}
        />
        <Input
          type="text"
          label="No. KTP"
          placeholder="Masukan nomor ktp"
          name="no_ktp"
          value={values.no_ktp}
          error={errors.no_ktp && touched.no_ktp && true}
          errorText={errors.no_ktp && touched.no_ktp && errors.no_ktp}
          onChange={handleChange}
        />
        <Row>
          <Col lg>
            <Input
              type="text"
              label="Tempat Lahir"
              placeholder="Masukan tempat lahir"
              name="tempat_lahir"
              value={values.tempat_lahir}
              error={errors.tempat_lahir && touched.tempat_lahir && true}
              errorText={errors.tempat_lahir}
              onChange={handleChange}
            />
          </Col>
          <Col lg>
            <DatePicker
              type="date"
              label="Tanggal Lahir"
              placeholderText="Pilih tgl. lahir"
              selected={
                values.tgl_lahir ? new Date(values.tgl_lahir) : undefined
              }
              error={errors.tgl_lahir && touched.tgl_lahir && true}
              errorText={
                errors.tgl_lahir && touched.tgl_lahir && errors.tgl_lahir
              }
              onChange={(date) => setFieldValue('tgl_lahir', date)}
            />
          </Col>
        </Row>
        <Row>
          <Col lg>
            <SelectSearch
              label="Jenis Kelamin"
              name="jenis_kelamin"
              defaultValue={dataJenisKelamin.find(
                (val) => val.value === values.jenis_kelamin
              )}
              option={dataJenisKelamin}
              onChange={(val) => setFieldValue('jenis_kelamin', val.value)}
              error={errors.jenis_kelamin && touched.jenis_kelamin && true}
              errorText={
                errors.jenis_kelamin &&
                touched.jenis_kelamin &&
                errors.jenis_kelamin
              }
            />
          </Col>
          <Col lg>
            <SelectSearch
              label="Agama"
              name="agama"
              defaultValue={dataAgama.find((val) => val.value === values.agama)}
              option={dataAgama}
              onChange={(val) => setFieldValue('agama', val.value)}
              error={errors.agama && touched.agama && true}
              errorText={errors.agama && touched.agama && errors.agama}
            />
          </Col>
        </Row>
        <SelectSearch
          label="Status Perkawinan"
          name="status_perkawinan"
          defaultValue={dataStatusPerkawinan.find(
            (val) => val.value === values.status_perkawinan
          )}
          option={dataStatusPerkawinan}
          onChange={(val) => setFieldValue('status_perkawinan', val.value)}
          error={errors.status_perkawinan && touched.status_perkawinan && true}
          errorText={
            errors.status_perkawinan &&
            touched.status_perkawinan &&
            errors.status_perkawinan
          }
        />

        {action !== 'READ' && action !== 'CREATE' && (
          <div className="text-right mt-4">
            <ActionButton
              variant={action === 'UPDATE' ? 'success' : 'primary'}
              text={`${action === 'UPDATE' ? 'Ubah' : 'Simpan'} Data Pribadi`}
              onClick={onSubmit}
              loading={submitLoading}
            />
          </div>
        )}
      </Card.Body>
    </Card>
  );
};
