import Services from '../../../services';

class SubkelompokAnggaranAPi {
  get() {
    return Services.get('/ang/sub_kelompok_anggaran');
  }

  getPage(page) {
    return Services.get(
      '/ang/sub_kelompok_anggaran/page/?per_page=10&page=' + page
    );
  }

  getKode() {
    return Services.get('/ang/sub_kelompok_anggaran/no_baru');
  }

  create(value) {
    return Services.post('/ang/sub_kelompok_anggaran', value);
  }

  update(value) {
    return Services.put('/ang/sub_kelompok_anggaran', value);
  }

  delete(value) {
    return Services.post('/ang/sub_kelompok_anggaran/delete', value);
  }
}

export default new SubkelompokAnggaranAPi();
