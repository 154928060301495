import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { isArray } from 'lodash';
import { CRUDLayout, BackButton, ActionButton, Alert } from 'components';
import { PekerjaApi } from 'api';
import {
  SectionDataAkun,
  SectionDataAlamat,
  SectionDataPegawai,
  SectionDataPendidikan,
  SectionDataKontak,
  SectionDataPerbankan,
  SectionDataPribadi,
  SectionDataKeluarga,
} from './Comps';

const PageIndicator = ({ page, onClick }) => {
  const PageIndicatorItems = ({ number, text, active, onClick, hasGrid }) => (
    <div className="position-relative">
      <div
        className="d-flex flex-column justify-content-center align-items-center mx-2"
        style={{
          width: '80px',
          cursor: onClick ? 'pointer' : 'default',
        }}
        onClick={onClick}
      >
        <div
          className={`d-flex justify-content-center align-items-center mb-2 rounded-pill text-white shadow-sm ${
            active && 'bg-primary'
          }`}
          style={{
            height: '30px',
            width: '30px',
            background: !active && '#C1C1C1',
            zIndex: 10,
          }}
        >
          {number}
        </div>
        <small className="text-center">{text}</small>
      </div>
      {hasGrid && (
        <div
          className="position-absolute"
          style={{
            height: '1px',
            width: '100%',
            top: 15,
            left: 50,
            background: '#C1C1C1',
            zIndex: 1,
          }}
        />
      )}
    </div>
  );

  return (
    <div
      className="d-flex justify-content-center align-items-center mb-2"
      style={{ position: 'relative' }}
    >
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ zIndex: 2 }}
      >
        <PageIndicatorItems
          hasGrid
          number="1"
          text="Informasi Umum"
          active={page === 1 && true}
          onClick={() => onClick(1)}
        />
        <PageIndicatorItems
          number="2"
          text="Informasi Tambahan"
          active={page === 2 && true}
          onClick={() => onClick(2)}
        />
      </div>
    </div>
  );
};

const PageInformasiUmum = () => {
  const { handleSubmit, isSubmitting } = useFormikContext();

  return (
    <div className="my-4">
      <SectionDataAkun action="CREATE" />
      <div className="mb-4" />
      <SectionDataPribadi action="CREATE" />
      <div className="mb-4" />
      <SectionDataPegawai action="CREATE" />
      <div className="my-4 text-right">
        <ActionButton
          text="Tambah Pekerja"
          onClick={handleSubmit}
          loading={isSubmitting}
        />
      </div>
    </div>
  );
};

const PageInformasiTambahan = ({ data, id }) => {
  const history = useHistory();
  const [alert, setAlert] = useState({
    section: '',
    show: false,
    variant: 'primary',
    text: '',
  });

  return (
    <div className="my-4">
      <Row>
        <Col lg>
          {/* Section Data Alamat */}
          <Formik
            initialValues={{
              id_karyawan: id,
              id_provinsi_asal: data?.id_provinsi_asal,
              id_kabupaten_asal: data?.id_kabupaten_asal,
              id_kecamatan_asal: data?.id_kecamatan_asal,
              id_desa_asal: data?.id_desa_asal,
              alamat_asal: data?.alamat_asal,
              kode_pos_asal: data?.kode_pos_asal,
              id_provinsi_saat_ini: data?.id_provinsi_saat_ini,
              id_kabupaten_saat_ini: data?.id_kabupaten_saat_ini,
              id_kecamatan_saat_ini: data?.id_kecamatan_saat_ini,
              id_desa_saat_ini: data?.id_desa_saat_ini,
              alamat_saat_ini: data?.alamat_saat_ini,
              kode_pos_saat_ini: data?.kode_pos_saat_ini,
            }}
            validationSchema={Yup.object().shape({
              id_provinsi_asal: Yup.string()
                .required('Pilih provinsi asal')
                .nullable(),
              id_kabupaten_asal: Yup.string()
                .required('Pilih kabupaten asal')
                .nullable(),
              id_kecamatan_asal: Yup.string()
                .required('Pilih kecamatan asal')
                .nullable(),
              id_desa_asal: Yup.string().required('Pilih desa asal').nullable(),
              alamat_asal: Yup.string()
                .required('Masukan alamat asal')
                .nullable(),
              kode_pos_asal: Yup.number()
                .required('Masukan kode pos asal')
                .typeError('Format kode pos tidak valid')
                .test({
                  test: (val) => val?.toString().length === 5,
                  message: 'Kode pos harus 5 karakter',
                })
                .nullable(),
              id_provinsi_saat_ini: Yup.string()
                .required('Pilih provinsi saat ini')
                .nullable(),
              id_kabupaten_saat_ini: Yup.string()
                .required('Pilih kabupaten saat ini')
                .nullable(),
              id_kecamatan_saat_ini: Yup.string()
                .required('Pilih kecamatan saat ini')
                .nullable(),
              id_desa_saat_ini: Yup.string()
                .required('Pilih desa saat ini')
                .nullable(),
              alamat_saat_ini: Yup.string()
                .required('Masukan alamat saat ini')
                .nullable(),
              kode_pos_saat_ini: Yup.string()
                .typeError('Format kode pos tidak valid')
                .required('Masukan kode pos saat ini')
                .test({
                  test: (val) => val?.toString().length === 5,
                  message: 'Kode pos harus 5 karakter',
                })
                .nullable(),
            })}
            onSubmit={(values, { setSubmitting }) =>
              PekerjaApi.updateDataAlamat(values)
                .then(() =>
                  setAlert({
                    section: 'alamat',
                    show: true,
                    variant: 'primary',
                    text: 'Data alamat berhasil diubah!',
                  })
                )
                .catch((err) =>
                  setAlert({
                    section: 'alamat',
                    show: true,
                    variant: 'danger',
                    text: isArray(err?.response?.data?.error)
                      ? err?.response?.data?.error?.map((val) => <li>{val}</li>)
                      : err?.response?.data?.message,
                  })
                )
                .finally(() => setSubmitting(false))
            }
          >
            {({ isSubmitting, handleSubmit }) => (
              <div className="mb-3">
                <SectionDataAlamat
                  action="CREATE"
                  submitLoading={isSubmitting}
                  onSubmit={handleSubmit}
                  alert={alert?.section === 'alamat' && alert}
                />
              </div>
            )}
          </Formik>
          <div className="mb-4" />

          {/* Section Data Pendidikan */}
          <Formik
            initialValues={{
              id_karyawan: id,
              id_jenjang_pendidikan: data?.id_jenjang_pendidikan,
              lembaga_pendidikan: data?.lembaga_pendidikan,
              tahun_lulus: data?.tahun_selesai,
            }}
            validationSchema={Yup.object().shape({})}
            onSubmit={(values, { setSubmitting }) =>
              PekerjaApi.updateDataPendidikan(values)
                .then(() =>
                  setAlert({
                    section: 'pendidikan',
                    show: true,
                    variant: 'primary',
                    text: 'Data pendidikan berhasil diubah!',
                  })
                )
                .catch((err) =>
                  setAlert({
                    section: 'pendidikan',
                    show: true,
                    variant: 'danger',
                    text: isArray(err?.response?.data?.error)
                      ? err?.response?.data?.error?.map((val) => <li>{val}</li>)
                      : err?.response?.data?.message,
                  })
                )
                .finally(() => setSubmitting(false))
            }
          >
            {({ isSubmitting, handleSubmit }) => (
              <div className="mb-3">
                <SectionDataPendidikan
                  action="CREATE"
                  submitLoading={isSubmitting}
                  onSubmit={handleSubmit}
                  alert={alert?.section === 'pendidikan' && alert}
                />
              </div>
            )}
          </Formik>
        </Col>
        <Col lg>
          {/* Section Data Kontak */}
          <Formik
            initialValues={{
              id_karyawan: id,
              no_telp: data?.no_telp,
              no_hp: data?.no_hp,
              email: data?.email,
              kontak_darurat: data?.kontak_darurat,
              keterangan_kontak_darurat: data?.keterangan_kontak_darurat,
            }}
            validationSchema={Yup.object().shape({
              no_telp: Yup.number()
                .required('Masukan nomor telephone')
                .typeError('Nomor telephone harus berupa angka')
                .test({
                  test: (val) => val?.toString().length >= 10,
                  message: 'Panjang no. telp minimal 10 karakter',
                })
                .test({
                  test: (val) => val?.toString().length <= 13,
                  message: 'Panjang no. telp maksimal 13 karakter',
                })
                .nullable(),
              no_hp: Yup.number()
                .typeError('Nomor handphone harus berupa angka')
                .test({
                  test: (val) => (val ? val?.toString().length >= 10 : true),
                  message: 'Panjang no. handphone minimal 10 karakter',
                })
                .test({
                  test: (val) => (val ? val?.toString().length <= 13 : true),
                  message: 'Panjang no. handphone maksimal 13 karakter',
                })
                .nullable(),
              email: Yup.string().email('Format email tidak valid').nullable(),
              kontak_darurat: Yup.string()
                .required('Masukan kontak darurat')
                .nullable(),
            })}
            onSubmit={(values, { setSubmitting }) =>
              PekerjaApi.updateDataKontak(values)
                .then(() =>
                  setAlert({
                    section: 'kontak',
                    show: true,
                    variant: 'primary',
                    text: 'Data kontak berhasil diubah!',
                  })
                )
                .catch((err) =>
                  setAlert({
                    section: 'kontak',
                    show: true,
                    variant: 'danger',
                    text: isArray(err?.response?.data?.error)
                      ? err?.response?.data?.error?.map((val) => <li>{val}</li>)
                      : err?.response?.data?.message,
                  })
                )
                .finally(() => setSubmitting(false))
            }
          >
            {({ isSubmitting, handleSubmit }) => (
              <div className="mb-3">
                <SectionDataKontak
                  action="CREATE"
                  submitLoading={isSubmitting}
                  onSubmit={handleSubmit}
                  alert={alert?.section === 'kontak' && alert}
                />
              </div>
            )}
          </Formik>
          <div className="mb-4" />

          {/* Section Data Perbankan */}
          <Formik
            initialValues={{
              id_karyawan: id,
              kode_bank: data?.kode_bank,
              no_rekening: data?.no_rekening,
              atas_nama: data?.atas_nama,
              cabang: data?.cabang,
            }}
            validationSchema={Yup.object().shape({
              kode_bank: Yup.string().required('Pilih bank').nullable(),
              no_rekening: Yup.string()
                .required('Masukan nomor rekening')
                .nullable(),
              atas_nama: Yup.string()
                .required('Masukan atas nama rekening')
                .nullable(),
            })}
            onSubmit={(values, { setSubmitting }) =>
              PekerjaApi.updateDataBank(values)
                .then(() =>
                  setAlert({
                    section: 'bank',
                    show: true,
                    variant: 'primary',
                    text: 'Data bank berhasil diubah!',
                  })
                )
                .catch((err) =>
                  setAlert({
                    section: 'bank',
                    show: true,
                    variant: 'danger',
                    text: isArray(err?.response?.data?.error)
                      ? err?.response?.data?.error?.map((val) => <li>{val}</li>)
                      : err?.response?.data?.message,
                  })
                )
                .finally(() => setSubmitting(false))
            }
          >
            {({ isSubmitting, handleSubmit }) => (
              <div className="mb-3">
                <SectionDataPerbankan
                  action="CREATE"
                  submitLoading={isSubmitting}
                  onSubmit={handleSubmit}
                  alert={alert?.section === 'bank' && alert}
                />
              </div>
            )}
          </Formik>
        </Col>
      </Row>

      {/* Section Data Keluarga */}
      <div>
        <SectionDataKeluarga id={id} action="CREATE" />
      </div>

      <div className="my-4 text-right">
        <ActionButton
          text="Selesai Tambah Pekerja"
          onClick={() => history.goBack()}
        />
      </div>
    </div>
  );
};

const PekerjaCreate = ({ setNavbarTitle }) => {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [idPekerja, setIdPekerja] = useState('');
  const [alert, setAlert] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const formInitialValues = {
    username: '',
    password: '',
    password_confirmation: '',
    nama_karyawan: '',
    no_ktp: '',
    tempat_lahir: '',
    tgl_lahir: '',
    jenis_kelamin: '',
    agama: '',
    status_perkawinan: '',
    id_jabatan: '',
    no_npwp: '',
    id_jenis_pekerja: '',
    id_status_pekerja: '',
    id_status_pekerja_detail: '',
    no_karyawan: '',
    id_jabatan: '',
    id_lokasi_kerja: '',
    tgl_bergabung: '',
    tgl_pengangkatan: '',
  };

  const formValidationSchema = Yup.object().shape({
    nama_karyawan: Yup.string().required('Masukan nama Pegawai').nullable(),
    no_ktp: Yup.string()
      .required('Masukan nomor ktp')
      .test({
        test: (val) => val?.toString().length === 16,
        message: 'Panjang KTP harus 16 karakter',
      })
      .nullable(),
    tempat_lahir: Yup.string().required('Masukan tempat lahir').nullable(),
    tgl_lahir: Yup.string().required('Pilih tanggal lahir').nullable(),
    jenis_kelamin: Yup.string().required('Pilih jenis kelamin').nullable(),
    agama: Yup.string().required('Pilih agama').nullable(),
    status_perkawinan: Yup.string()
      .required('Pilih status perkawinan')
      .nullable(),
    id_jenis_pekerja: Yup.string().required('Pilih Jenis Pegawai'),
    id_status_pekerja: Yup.string().required('Pilih Status Pegawai'),
    id_jabatan: Yup.string().required('Pilih jabatan'),
    tgl_bergabung: Yup.string().required('Pilih tanggal bergabung'),
    tgl_pengangkatan: Yup.string().required('Pilih tanggal pengangkatan'),
    id_lokasi_kerja: Yup.string().required('Pilih Lokasi Kerja'),
  });

  const formSubmitHandler = (values, { setSubmitting }) => {
    PekerjaApi.createDataPribadi({
      username: values.username,
      password: values.password,
      password_confirmation: values.password_confirmation,
      nama_karyawan: values.nama_karyawan,
      no_ktp: values.no_ktp,
      tempat_lahir: values.tempat_lahir,
      tgl_lahir: values.tgl_lahir,
      jenis_kelamin: values.jenis_kelamin,
      agama: values.agama,
      status_perkawinan: values.status_perkawinan,
      id_jabatan: values.id_jabatan,
      id_status_pekerja: values.id_status_pekerja,
      no_npwp: values.no_npwp,
      no_karyawan: values.no_karyawan,
    })
      .then((res) => {
        PekerjaApi.createDataKepegawaian({
          id_karyawan: res.data.id_karyawan,
          id_lokasi_kerja: values.id_lokasi_kerja,
          tgl_bergabung: values.tgl_bergabung,
          tgl_pengangkatan: values.tgl_pengangkatan,
          id_jabatan: values.id_jabatan,
          id_jenis_pekerja: values.id_jenis_pekerja,
          id_status_pekerja: values.id_status_pekerja,
          id_status_pekerja_detail: values.id_status_pekerja_detail,
        }).catch((err) =>
          alert(
            `Data kepegawaian tidak tersimpan (${err?.response?.data?.message})`
          )
        );
        setIdPekerja(res?.data?.id_karyawan);
        setAlert({
          show: true,
          variant: 'primary',
          text: 'Data berhasil disimpan!',
        });
        setPage(2);
      })
      .catch((err) =>
        setAlert({
          section: 'kepegawaian',
          show: true,
          variant: 'danger',
          text: isArray(err?.response?.data?.error)
            ? err?.response?.data?.error?.map((val) => <li>{val}</li>)
            : err?.response?.data?.message,
        })
      )
      .finally(() => {
        setSubmitting(false);
        window.scrollTo(0, 0);
      });
  };

  useEffect(() => {
    setNavbarTitle('Tambah Pekerja');
  }, []);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadButtonSection>
          <BackButton onClick={() => history.goBack()} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      <PageIndicator page={page} onClick={() => {}} />
      <div className="mt-3" />
      <Alert show={alert.show} variant={alert.variant} text={alert.text} />
      {page === 1 ? (
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          <PageInformasiUmum />
        </Formik>
      ) : (
        <PageInformasiTambahan id={idPekerja} />
      )}
    </CRUDLayout>
  );
};

export default PekerjaCreate;
