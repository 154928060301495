import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import {
  Input,
  SelectSearch,
  Alert,
  ActionButton,
  InfoItemVertical,
} from 'components';
import { PekerjaApi } from 'api';

export const SectionDataPendidikan = ({
  action,
  onSubmit,
  submitLoading,
  alert,
}) => {
  const { values, errors, touched, handleChange, setFieldValue, setValues } =
    useFormikContext();
  const [dataJenjangPendidikan, setDataJenjangPendidikan] = useState({
    loading: false,
    error: false,
    data: [],
  });
  const dataTahunLulus = _.range(new Date().getFullYear(), 1950 - 1).map(
    (val) => ({ label: val, value: val })
  );

  const getDataJenjangPendidikan = () => {
    setDataJenjangPendidikan({ loading: true, error: false, data: [] });

    PekerjaApi.getJenjangPendidikan()
      .then((res) => {
        setDataJenjangPendidikan({
          loading: false,
          error: false,
          data: res?.data?.data?.map((val) => ({
            label: val?.nama_jenjang_pendidikan,
            value: val?.id_jenjang_pendidikan,
          })),
        });
      })
      .catch((err) => {
        setDataJenjangPendidikan({
          loading: false,
          error: true,
          data: [],
        });
      });
  };

  useEffect(() => {
    getDataJenjangPendidikan();
  }, []);

  if (action === 'READ') {
    return (
      <Card>
        <Card.Header>
          <b>Data Pendidikan</b>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col lg>
              <InfoItemVertical
                label="Jenjang Pendidikan"
                text={values.nama_jenjang_pendidikan ?? '-'}
              />
            </Col>
            <Col lg>
              <InfoItemVertical
                label="Tahun Lulus"
                text={values.tahun_selesai ?? '-'}
              />
            </Col>
          </Row>
          <InfoItemVertical
            label="Lembaga Pendidikan"
            text={values.lembaga_pendidikan ?? '-'}
          />
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card>
      <Card.Header>
        <b>Data Pendidikan</b>
      </Card.Header>
      <Card.Body>
        <Alert show={alert?.show} variant={alert?.variant} text={alert?.text} />
        <Row>
          <Col lg>
            <SelectSearch
              label="Jenjang Pendidikan"
              loading={dataJenjangPendidikan.loading}
              defaultValue={dataJenjangPendidikan.data.find(
                (val) => val.value === values.id_jenjang_pendidikan
              )}
              option={dataJenjangPendidikan.data}
              onChange={(val) => {
                setValues({
                  ...values,
                  id_jenjang_pendidikan: val.value,
                });
              }}
              errorFetch={dataJenjangPendidikan.error}
              error={
                errors.id_jenjang_pendidikan &&
                touched.id_jenjang_pendidikan &&
                true
              }
              errorText={
                errors.id_jenjang_pendidikan &&
                touched.id_jenjang_pendidikan &&
                errors.id_jenjang_pendidikan
              }
            />
          </Col>
          <Col lg>
            <SelectSearch
              label="Tahun lulus"
              name="tahun_lulus"
              defaultValue={dataTahunLulus.find(
                (val) =>
                  val?.value?.toString() === values?.tahun_lulus?.toString()
              )}
              option={dataTahunLulus}
              onChange={(val) => setFieldValue('tahun_lulus', val.value)}
              error={errors.tahun_lulus && touched.tahun_lulus && true}
              errorText={
                errors.tahun_lulus && touched.tahun_lulus && errors.tahun_lulus
              }
            />
          </Col>
        </Row>
        <Input
          type="text"
          label="Lembaga Pendidikan"
          placeholder="Masukan lembaga pendidikan"
          name="lembaga_pendidikan"
          value={values.lembaga_pendidikan}
          error={
            errors.lembaga_pendidikan && touched.lembaga_pendidikan && true
          }
          errorText={
            errors.lembaga_pendidikan &&
            touched.lembaga_pendidikan &&
            errors.lembaga_pendidikan
          }
          onChange={handleChange}
        />

        {action !== 'READ' && (
          <div className="text-right mt-4">
            <ActionButton
              variant={action === 'UPDATE' ? 'success' : 'primary'}
              text={`${
                action === 'UPDATE' ? 'Ubah' : 'Simpan'
              } Data Pendidikan`}
              onClick={onSubmit}
              loading={submitLoading}
            />
          </div>
        )}
      </Card.Body>
    </Card>
  );
};
