import Services from '../../../services';

class UnitOrganisasiAnggaranApi {
  get() {
    return Services.get('/ang/unit_organisasi');
  }
  create(value) {
    return Services.post('/ang/unit_organisasi', value);
  }

  update(value) {
    return Services.put('/ang/unit_organisasi', value);
  }
}

export default new UnitOrganisasiAnggaranApi();
