import React from 'react';

const Th = ({ children, style }) => {
  return (
    <th
      className="text-center align-middle text-capitalize"
      style={{
        fontSize: '12px',
        ...style,
      }}
    >
      {children}
    </th>
  );
};

export default Th;
