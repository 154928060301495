import React, { useState, useEffect } from 'react';
import { ButtonGroup, Card, Modal, Spinner } from 'react-bootstrap';
import {
  IoAdd,
  IoCheckmark,
  IoClose,
  IoPencilOutline,
  IoTrashBinOutline,
} from 'react-icons/io5';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { isArray, values } from 'lodash';
import {
  Table,
  TBody,
  THead,
  Th,
  Td,
  DataStatus,
  Input,
  ActionButton,
  SelectSearch,
  DatePicker,
  Alert,
} from 'components';
import { PekerjaApi } from 'api';

const ModalDelete = ({ show, onHide, onSubmit, data, loading }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <span className="text-danger">Hapus Data Keluarga</span>
      </Modal.Header>
      <Modal.Body className="text-center">
        <h5>
          <span>Hapus data dengan nama: </span>
          <br />
          <b>{data?.nama_anggota_keluarga}</b>
        </h5>
        <small className="text-danger">
          Data yang dihapus tidak dapat dikembalikan!
        </small>
        <div className="d-flex justify-content-center mt-3">
          <ActionButton
            variant="outline-secondary"
            className="m-1"
            text="Batal"
            onClick={onHide}
          />
          <ActionButton
            variant="danger"
            className="m-1"
            text="Hapus Data"
            loading={loading}
            onClick={onSubmit}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

const TableRow = ({
  defaultAction = 'READ',
  data,
  index,
  onCreateData,
  onUpdateData,
  onDeleteData,
}) => {
  const [action, setAction] = useState(defaultAction);
  const dataJenisAnggota = [
    { label: 'Ayah', value: 'Ayah' },
    { label: 'Ibu', value: 'Ibu' },
    { label: 'Saudara Kandung', value: 'Saudara Kandung' },
    { label: 'Suami', value: 'Suami' },
    { label: 'Istri', value: 'Istri' },
    { label: 'Anak', value: 'Anak' },
    { label: 'Paman', value: 'Paman' },
    { label: 'Bibi', value: 'Bibi' },
    { label: 'Keponakan', value: 'Keponakan' },
    { label: 'Sepupu', value: 'Sepupu' },
    { label: 'Kakek', value: 'Kakek' },
    { label: 'Nenek', value: 'Nenek' },
  ];

  if (action === 'READ' || action === 'READONLY') {
    return (
      <tr>
        <Td className="text-center">{action !== 'CREATE' ? index + 1 : ''}</Td>
        <Td>{data?.nama_anggota_keluarga ?? '-'}</Td>
        <Td>{data?.jenis_anggota_keluarga ?? '-'}</Td>
        <Td>{data?.tgl_lahir ?? '-'}</Td>
        <Td>{data?.keterangan ?? '-'}</Td>
        {action !== 'READONLY' && (
          <Td>
            <ButtonGroup size="sm">
              <ActionButton
                variant="success"
                text={<IoPencilOutline />}
                onClick={() => setAction('UPDATE')}
              />
              <ActionButton
                variant="danger"
                text={<IoTrashBinOutline />}
                onClick={() => onDeleteData(data)}
              />
            </ButtonGroup>
          </Td>
        )}
      </tr>
    );
  }

  return (
    <Formik
      initialValues={{
        id_anggota_keluarga: data?.id_anggota_keluarga,
        jenis_anggota_keluarga: data?.jenis_anggota_keluarga,
        nama_anggota_keluarga: data?.nama_anggota_keluarga,
        tgl_lahir: data?.tgl_lahir,
        keterangan: data?.keterangan,
      }}
      validationSchema={Yup.object().shape({
        jenis_anggota_keluarga: Yup.string().required(),
        nama_anggota_keluarga: Yup.string().required(),
        tgl_lahir: Yup.string().required(),
      })}
      onSubmit={(values, formik) => {
        action === 'UPDATE'
          ? onUpdateData(values, formik)
          : onCreateData(values, formik);
      }}
    >
      {({
        values,
        errors,
        touched,
        setValues,
        setFieldValue,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => (
        <tr>
          <Td className="text-center">
            {action !== 'CREATE' ? index + 1 : ''}
          </Td>
          <Td>
            <Input
              noMargin
              type="text"
              name="nama_anggota_keluarga"
              value={values.nama_anggota_keluarga}
              onChange={handleChange}
              error={
                errors.nama_anggota_keluarga &&
                touched.nama_anggota_keluarga &&
                true
              }
            />
          </Td>
          <Td>
            <SelectSearch
              noMargin
              menuPosition="fixed"
              menuShouldBlockScroll
              defaultValue={dataJenisAnggota.find(
                (val) => val.value === values.jenis_anggota_keluarga
              )}
              option={dataJenisAnggota}
              onChange={(val) => {
                setValues({
                  ...values,
                  jenis_anggota_keluarga: val.value,
                });
              }}
              error={
                errors.jenis_anggota_keluarga &&
                touched.jenis_anggota_keluarga &&
                true
              }
            />
          </Td>
          <Td>
            <DatePicker
              noMargin
              selected={
                values.tgl_lahir ? new Date(values.tgl_lahir) : undefined
              }
              error={errors.tgl_lahir && touched.tgl_lahir && true}
              onChange={(date) => setFieldValue('tgl_lahir', date)}
            />
          </Td>
          <Td>
            <Input
              noMargin
              type="text"
              name="keterangan"
              value={values.keterangan}
              onChange={handleChange}
            />
          </Td>
          <Td>
            {action === 'CREATE' ? (
              <ActionButton
                disable={isSubmitting}
                size="sm"
                text={
                  isSubmitting ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    <IoAdd />
                  )
                }
                className="col"
                onClick={handleSubmit}
              />
            ) : (
              <ButtonGroup size="sm">
                <ActionButton
                  disable={isSubmitting}
                  variant="outline-success"
                  text={
                    isSubmitting ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <IoCheckmark />
                    )
                  }
                  onClick={handleSubmit}
                />
                <ActionButton
                  variant="outline-danger"
                  text={<IoClose />}
                  onClick={() => {
                    setAction('READ');
                    setValues({ ...data });
                  }}
                />
              </ButtonGroup>
            )}
          </Td>
        </tr>
      )}
    </Formik>
  );
};

export const SectionDataKeluarga = ({ id, action }) => {
  const [dataKeluarga, setDataKeluarga] = useState({
    loading: false,
    error: false,
    data: [],
  });
  const [alert, setAlert] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [modal, setModal] = useState({
    show: false,
    loading: false,
    data: {},
  });

  const getDataKeluarga = () => {
    setDataKeluarga({ loading: true, error: false, data: [] });

    PekerjaApi.getKeluarga(id)
      .then((res) => {
        setDataKeluarga({
          loading: false,
          error: false,
          data: res?.data?.data_keluarga,
        });
      })
      .catch((err) => {
        setDataKeluarga({
          loading: false,
          error: true,
          data: [],
        });
      });
  };

  const createDataKeluarga = (values, { setSubmitting }) => {
    PekerjaApi.createDataKeluarga({
      id_karyawan: id,
      data_keluarga: [
        {
          jenis_anggota_keluarga: values.jenis_anggota_keluarga,
          nama_anggota_keluarga: values.nama_anggota_keluarga,
          tgl_lahir: values.tgl_lahir,
          keterangan: values.keterangan,
        },
      ],
    })
      .then(() => {
        setAlert({
          show: true,
          variant: 'primary',
          text: 'Data berhasil ditambah!',
        });
      })
      .catch((err) =>
        setAlert({
          show: true,
          variant: 'danger',
          text: isArray(err?.response?.data?.error)
            ? err?.response?.data?.error?.map((val) => <li>{val}</li>)
            : err?.response?.data?.message,
        })
      )
      .finally(() => {
        getDataKeluarga();
        setSubmitting(false);
      });
  };

  const updateDataKeluarga = (values, { setSubmitting }) => {
    PekerjaApi.updateDataKeluarga({
      id_anggota_keluarga: values.id_anggota_keluarga,
      jenis_anggota_keluarga: values.jenis_anggota_keluarga,
      nama_anggota_keluarga: values.nama_anggota_keluarga,
      tgl_lahir: values.tgl_lahir,
      keterangan: values.keterangan,
    })
      .then(() => {
        setAlert({
          show: true,
          variant: 'primary',
          text: 'Data berhasil diubah!',
        });
      })
      .catch((err) =>
        setAlert({
          show: true,
          variant: 'danger',
          text: isArray(err?.response?.data?.error)
            ? err?.response?.data?.error?.map((val) => <li>{val}</li>)
            : err?.response?.data?.message,
        })
      )
      .finally(() => {
        getDataKeluarga();
        setSubmitting(false);
      });
  };

  const deleteDataKeluarga = () => {
    setModal({
      ...modal,
      loading: true,
    });

    PekerjaApi.deleteKeluarga({
      id_anggota_keluarga: modal?.data?.id_anggota_keluarga,
    })
      .then(() => {
        setAlert({
          show: true,
          variant: 'primary',
          text: 'Data berhasil dihapus!',
        });
      })
      .catch((err) =>
        setAlert({
          show: true,
          variant: 'danger',
          text: isArray(err?.response?.data?.error)
            ? err?.response?.data?.error?.map((val) => <li>{val}</li>)
            : err?.response?.data?.message,
        })
      )
      .finally(() => {
        getDataKeluarga();
        setModal({
          show: false,
          data: {},
          loading: false,
        });
      });
  };

  useEffect(() => {
    getDataKeluarga();
  }, []);

  return (
    <Card>
      <Card.Header>
        <b>Data Keluarga</b>
      </Card.Header>
      <Card.Body>
        <Alert show={alert.show} variant={alert.variant} text={alert.text} />
        <Table>
          <THead>
            <tr>
              <Th style={{ width: 40 }}>No</Th>
              <Th>Nama Anggota Keluarga</Th>
              <Th>Status Anggota Keluarga</Th>
              <Th>Tgl. Lahir</Th>
              <Th>Keterangan</Th>
              {action !== 'READ' && <Th style={{ width: 40 }}>Aksi</Th>}
            </tr>
          </THead>
          <TBody>
            {action !== 'READ' && (
              <TableRow
                defaultAction="CREATE"
                onCreateData={createDataKeluarga}
              />
            )}
            {dataKeluarga.loading ||
            dataKeluarga?.data?.length < 1 ||
            dataKeluarga.error ? (
              <tr>
                <Td colSpan={6}>
                  <DataStatus
                    loading={dataKeluarga.loading}
                    text={
                      dataKeluarga.loading
                        ? 'Memuat data . . .'
                        : dataKeluarga.error
                        ? 'Data gagal dimuat'
                        : 'Tidak ada data'
                    }
                  />
                </Td>
              </tr>
            ) : (
              <>
                {dataKeluarga?.data?.map((val, i) => (
                  <TableRow
                    data={val}
                    defaultAction={action == 'READ' ? 'READONLY' : 'READ'}
                    index={i}
                    onUpdateData={updateDataKeluarga}
                    onDeleteData={(val) => setModal({ show: true, data: val })}
                  />
                ))}
              </>
            )}
          </TBody>
        </Table>
        <ModalDelete
          show={modal.show}
          data={modal.data}
          loading={modal.loading}
          onHide={() => {
            setModal({
              show: false,
              data: {},
              loading: false,
            });
          }}
          onSubmit={deleteDataKeluarga}
        />
      </Card.Body>
    </Card>
  );
};
