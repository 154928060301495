import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { IoArrowUpOutline } from 'react-icons/io5';
import {
  Select,
  Switch,
  ActionButton,
  DataStatus,
  SelectSearch,
} from '../../../../components';

const HakAksesAplikasi = ({ setNavbarTitle }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState('');
  const [dataKaryawan, setDataKaryawan] = useState([
    { label: 'Karyawan 01', value: '1' },
    { label: 'Karyawan 02', value: '2' },
    { label: 'Karyawan 03', value: '3' },
  ]);

  useEffect(() => {
    setNavbarTitle('Hak Akses Aplikasi');
  }, []);

  const MustSelectDataIndicator = () => (
    <div className="d-flex justify-content-center mt-3">
      <IoArrowUpOutline size="18" />
      <h5 className="ml-2">Pilih data terlebih dahulu</h5>
    </div>
  );

  const DivisiTable = () => {
    return (
      <>
        <div className="bg-white rounded shadow-sm">
          <Table>
            <thead>
              <th>No.</th>
              <th>Kode Hak Akses</th>
              <th>Nama Hak Akses</th>
              <th>Aksi</th>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>T01</td>
                <td>Test 01</td>
                <td>
                  <Switch checked={false} />
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        <ActionButton text="Simpan Hak Akses Aplikasi" />
      </>
    );
  };

  return (
    <div
      className="p-1"
      style={{
        minHeight: '100vh',
        overflow: 'auto',
      }}
    >
      <SelectSearch
        label="Karyawan"
        placeholder="Pilih karyawan"
        option={dataKaryawan}
        onChange={(val) => {
          setIsLoading(true);
          setUserId(val.value);

          setTimeout(() => {
            setIsLoading(false);
          }, 1000);
        }}
      />
      {isLoading ? (
        <DataStatus loading={true} text="Memuat..." />
      ) : userId ? (
        <DivisiTable />
      ) : (
        <MustSelectDataIndicator />
      )}
    </div>
  );
};

export default HakAksesAplikasi;
