import Services from '../../../services';

class ProgramAnggaranApi {
  get() {
    return Services.get('/ang/program');
  }

  getKode() {
    return Services.get('/ang/program/no_baru');
  }

  create(value) {
    return Services.post('/ang/program', value);
  }

  update(value) {
    return Services.put('/ang/program', value);
  }

  delete(value) {
    return Services.post('/ang/program/delete', value);
  }
}

export default new ProgramAnggaranApi();
