import Services from '../../../services';

class WilayahApi {
  getProvinsi() {
    return Services.get('/hrda/wilayah/dropdown_provinsi');
  }

  getKabupaten(id) {
    return Services.get('/hrda/wilayah/dropdown_kabupaten?id_provinsi=' + id);
  }

  getKecamatan(id) {
    return Services.get('/hrda/wilayah/dropdown_kecamatan?id_kabupaten=' + id);
  }

  getDesa(id) {
    return Services.get('/hrda/wilayah/dropdown_desa?id_kecamatan=' + id);
  }
}

export default new WilayahApi();
