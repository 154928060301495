import React from 'react';
import { Card } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import {
  Input,
  TextArea,
  Alert,
  ActionButton,
  InfoItemVertical,
} from 'components';

export const SectionDataKontak = ({
  action,
  onSubmit,
  submitLoading,
  alert,
}) => {
  const { values, errors, touched, handleChange } = useFormikContext();

  if (action === 'READ') {
    return (
      <Card>
        <Card.Header>
          <b>Data Kontak</b>
        </Card.Header>
        <Card.Body>
          <InfoItemVertical label="No. Telp" text={values?.no_telp ?? '-'} />
          <InfoItemVertical label="No. Handphone" text={values?.no_hp ?? '-'} />
          <InfoItemVertical label="Email" text={values?.email ?? '-'} />
          <InfoItemVertical
            label="Kontak Darurat"
            text={values?.kontak_darurat ?? '-'}
          />
          <InfoItemVertical
            label="Keterangan Kontak Darurat"
            text={values?.keterangan_kontak_darurat ?? '-'}
          />
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card>
      <Card.Header>
        <b>Data Kontak</b>
      </Card.Header>
      <Card.Body>
        <Alert show={alert?.show} variant={alert?.variant} text={alert?.text} />
        <Input
          type="text"
          label="No. Telp"
          placeholder="Masukan nomor telephone"
          name="no_telp"
          value={values.no_telp}
          error={errors.no_telp && touched.no_telp && true}
          errorText={errors.no_telp && touched.no_telp && errors.no_telp}
          onChange={handleChange}
        />
        <Input
          type="text"
          label="No. HP"
          placeholder="Masukan nomor handphone"
          name="no_hp"
          value={values.no_hp}
          error={errors.no_hp && touched.no_hp && true}
          errorText={errors.no_hp && touched.no_hp && errors.no_hp}
          onChange={handleChange}
        />
        <Input
          type="email"
          label="Email"
          placeholder="Masukan email"
          name="email"
          value={values.email}
          error={errors.email && touched.email && true}
          errorText={errors.email && touched.email && errors.email}
          onChange={handleChange}
        />
        <Input
          type="text"
          label="Kontak Darurat"
          placeholder="Masukan kontak darurat"
          name="kontak_darurat"
          value={values.kontak_darurat}
          error={errors.kontak_darurat && touched.kontak_darurat && true}
          errorText={
            errors.kontak_darurat &&
            touched.kontak_darurat &&
            errors.kontak_darurat
          }
          onChange={handleChange}
        />
        <TextArea
          label="Keterangan Kontak Darurat"
          placeholder="Masukan keterangan kontak darurat"
          name="keterangan_kontak_darurat"
          value={values.keterangan_kontak_darurat}
          error={
            errors.keterangan_kontak_darurat &&
            touched.keterangan_kontak_darurat &&
            true
          }
          errorText={
            errors.keterangan_kontak_darurat &&
            touched.keterangan_kontak_darurat &&
            errors.keterangan_kontak_darurat
          }
          onChange={handleChange}
        />
        {action !== 'READ' && (
          <div className="text-right mt-4">
            <ActionButton
              variant={action === 'UPDATE' ? 'success' : 'primary'}
              text={`${action === 'UPDATE' ? 'Ubah' : 'Simpan'} Data Kontak`}
              onClick={onSubmit}
              loading={submitLoading}
            />
          </div>
        )}
      </Card.Body>
    </Card>
  );
};
