import Services from '../../../services';

class HirarkiApi {
  get() {
    return Services.get('/hrda/hirarki_unit_organisasi');
  }

  getDropdown() {
    return Services.get('/hrda/hirarki_unit_organisasi/dropdown');
  }

  getPage(page, dataLength, key) {
    return Services.get(
      `/hrda/hirarki_unit_organisasi/page/?per_page=${dataLength}&page=${page}&q=${key}`
    );
  }

  search(key) {
    return Services.get(
      '/hrda/hirarki_unit_organisasi/page/?per_page=10&page=1&q=' + key
    );
  }

  show(value) {
    return Services.put('/hrda/hirarki_unit_organisasi/show', value);
  }

  hide(value) {
    return Services.put('/hrda/hirarki_unit_organisasi/hide', value);
  }

  create(value) {
    return Services.post('/hrda/hirarki_unit_organisasi', value);
  }

  update(value) {
    return Services.put('/hrda/hirarki_unit_organisasi', value);
  }

  delete(value) {
    return Services.post('/hrda/hirarki_unit_organisasi/delete', value);
  }
}

export default new HirarkiApi();
