import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { Field, FieldArray, Formik, getIn } from 'formik';
import Axios from 'axios';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  CreateButton,
  ReadButton,
  DeleteButton,
  UpdateButton,
  SelectSearch,
  UpdateModal,
  ReadModal,
} from '../../../../components';
import { DateConvert, TableNumber } from '../../../../utilities';
import { HakAksesApprovalApi } from '../../../../api';
import { useIsGuest } from 'hooks';

const HakAksesApprovalNew = ({ setNavbarTitle }) => {
  const location = useLocation();
  const guest = useIsGuest();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataListHak, setDataListHak] = useState([]);
  const [isEditForm, setIsEditForm] = useState(false);
  const [isDetailForm, setIsDetailForm] = useState(false);
  const [kodeTransaksi, setKodeTransaksi] = useState('');
  const [dataModal, setDataModal] = useState({});
  const [dataJabatan, setDataJabatan] = useState([]);

  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    dataLength: '10',
    totalPage: '1',
    dataCount: '0',
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    Axios.all([
      HakAksesApprovalApi.getHak({
        q: searchConfig.key,
        page: paginationConfig.page,
        per_page: paginationConfig.dataLength,
      }),
      HakAksesApprovalApi.getJabatan(),
    ])
      .then(
        Axios.spread((res, jabatan) => {
          setDataListHak(res.data.data);
          setPaginationConfig({
            ...paginationConfig,
            dataCount: res.data.data_count,
            totalPage: res.data.total_page,
          });
          searchConfig.status &&
            setAlertConfig({
              // SHOW HIDE ALERT CONFIG
              show: searchConfig.key.length > 0 ? true : false,
              variant: 'primary',
              text: `Hasil dari pencarian: ${searchConfig.key}`,
            });
          setDataJabatan(jabatan.data.data);
        })
      )
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        })
      )
      .finally(() => {
        setIsPageLoading(false);
      });
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  useEffect(() => {
    setNavbarTitle('Hak Akses Approval');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    searchConfig.status,
    paginationConfig.page,
    paginationConfig.dataLength,
    searchConfig.key,
  ]);

  // KOMPONEN BARU UNTUK UPDATE HAK AKSES
  const UpdateModalForm = () => {
    // FORMIK ARRAYHELPER
    const arrayHelperRef = useRef();
    const [isModalLoading, setIsModalLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const [formInitialValues, setFormInitialValues] = useState({
      kode_transaksi: dataModal.kode_transaksi,
      pemeriksa: [],
      pengesah: {},
    });

    const getModalData = () => {
      HakAksesApprovalApi.getSingle(kodeTransaksi)
        .then(({ data }) => {
          // SET PENGESAH
          const pengesah = {
            ...data.data.jabatan_pengesah[0],
            jenis: 'PENGESAH',
            value: data.data.jabatan_pengesah[0].id_jabatan,
            label: findNamaJabatan(data.data.jabatan_pengesah[0].id_jabatan),
          };

          // SET PEMERIKSA
          // SORT PEMERIKSA BERDASARKAN level_jenis ASCENDING
          let pemeriksa = data.data.jabatan_pemeriksa.sort(
            (a, b) => a.level_jenis - b.level_jenis
          );
          // FIND NAMA JABATAN UNTUK SETIAP PEMERIKSA
          pemeriksa.map((val) => {
            val.jenis = 'PEMERIKSA';
            val.value = val.id_jabatan;
            val.label = findNamaJabatan(val.id_jabatan);
          });

          setFormInitialValues({
            kode_transaksi: data.data.kode_transaksi,
            pemeriksa: pemeriksa,
            pengesah: pengesah,
          });
        })
        .catch(() => {
          console.log('Gagal memuat data update!');
        })
        .finally(() => setIsModalLoading(false));
    };

    // FUNGSI UNTUK MENCARI NAMA JABATAN BERDASARKAN id_jabatan
    const findNamaJabatan = (id_jabatan) => {
      return (
        dataJabatan.find((val) => val.id_jabatan == id_jabatan)?.nama_jabatan ??
        'Pilih Jabatan'
      );
    };

    // KIRIM DATA KE SERVER
    const formSubmitHandler = (value) => {
      const finalValues = {
        kode_transaksi: kodeTransaksi,
        jabatan_approval: [...value.pemeriksa, value.pengesah],
      };

      console.log('values: ', value);
      console.log('finalValues:', finalValues);

      HakAksesApprovalApi.setHakAkses(finalValues)
        .then((res) => {
          setAlertConfig({
            show: true,
            variant: 'primary',
            text: 'Ubah data berhasil!',
          });
        })
        .catch((err) => {
          setAlertConfig({
            show: true,
            variant: 'danger',
            text: `Ubah data gagal! (${err})`,
          });
        })
        .finally(() => {
          setIsEditForm(false);
        });
    };

    useEffect(() => {
      // TRIGGER RENDER ULANG SELECT SEARCH
      // AGAR LABEL DAPAT TERUPDATE KETIKA HAPUS PEMERIKSA
      if (isLoading) {
        setTimeout(() => {
          setIsLoading(false);
        }, 100);
      }
    }, [isLoading]);

    useEffect(() => {
      getModalData();
      return () => {
        setIsModalLoading(false);
        setIsEditForm(false);
      };
    }, []);

    // VALIDASI SELECT FORM
    const validateSelectForm = (values) => {
      // CEK JIKA USER SUDAH MEMILIH PENGESAH
      if (Object.keys(values.pengesah).length === 0 || values.pengesah == '') {
        alert('Pilih Jabatan Pengesah');
        return false;
      }

      // Menyimpan Index Select Pemeriksa Yang Kosong
      let listEmptyIndex = [];

      // FILTER SELECT PEMERIKSA YANG BELUM DIPILIH
      values.pemeriksa.filter((val, index) => {
        if (val.value == '') {
          listEmptyIndex.push(index);
        }
      });

      // CEK JIKA ADA SELECT YANG BELUM DIPILIH
      if (listEmptyIndex.length > 0) {
        alert('Lengkapi Jabatan Pemeriksa');
        return false;
      }

      return true;
    };

    return (
      <UpdateModal show={isEditForm} onHide={() => setIsEditForm(false)}>
        {isModalLoading ? (
          <DataStatus loading={true} text="Memuat" />
        ) : (
          <Formik
            enableReinitialize
            initialValues={formInitialValues}
            // validationSchema={formValidationSchema}
            onSubmit={formSubmitHandler}
          >
            {({
              values,
              isSubmitting,
              handleSubmit,
              setFieldValue,
              submitForm,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Modal.Body>
                    <FieldArray
                      name="pemeriksa"
                      render={(arrayHelpers) => {
                        arrayHelperRef.current = arrayHelpers;
                        return (
                          <div>
                            {values.pemeriksa && values.pemeriksa.length > 0 ? (
                              values.pemeriksa.map((pemeriksa, index) => {
                                return (
                                  <div
                                    className="d-flex flex-row justify-content-between align-items-end"
                                    key={index}
                                  >
                                    <div className="col-md-10 pr-0">
                                      {/* FIELD SELECT PEMERIKSA */}
                                      <SelectSearch
                                        loading={isLoading}
                                        key={index}
                                        label={`Pemeriksa ${index + 1}`}
                                        placeholder={`Pilih Pemeriksa ${
                                          index + 1
                                        }`}
                                        defaultValue={
                                          values.pemeriksa[index] ?? {
                                            value: '',
                                            label: `Pilih Pemeriksa ${
                                              index + 1
                                            }`,
                                          }
                                        }
                                        onChange={(val) => {
                                          arrayHelpers.replace(index, val);
                                        }}
                                        option={dataJabatan.map((val) => {
                                          return {
                                            value: val.id_jabatan,
                                            label: val.nama_jabatan,

                                            id_jabatan: val.id_jabatan,
                                            jenis: 'PEMERIKSA',
                                            level_jenis: index + 1,
                                          };
                                        })}
                                      />
                                    </div>

                                    <div className="pb-2">
                                      <DeleteButton
                                        onClick={() => {
                                          arrayHelpers.remove(index);
                                          setIsLoading(true);
                                        }}
                                      />
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <div />
                            )}
                          </div>
                        );
                      }}
                    />

                    {/* FIELD SELECT PENYETUJU */}
                    <div className="col-md-10 pr-0">
                      <SelectSearch
                        // loading={isLoading}
                        name="pengesah"
                        label={'Pengesah'}
                        placeholder="Pilih Pengesah"
                        defaultValue={values.pengesah}
                        onChange={(val) => {
                          setFieldValue('pengesah', val);
                        }}
                        option={dataJabatan.map((val) => {
                          return {
                            value: val.id_jabatan,
                            label: val.nama_jabatan,

                            id_jabatan: val.id_jabatan,
                            jenis: 'PENGESAH',
                            level_jenis: '1',
                          };
                        })}
                      />
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <ActionButton
                      variant={isSubmitting ? 'light' : 'primary'}
                      text="Tambah Pemeriksa"
                      className="mt-2 px-4"
                      onClick={() => {
                        if (!isSubmitting) {
                          arrayHelperRef.current.insert(
                            values.pemeriksa.length + 1,
                            {
                              value: '',
                              label: '',
                            }
                          );
                        }
                      }}
                    />

                    <ActionButton
                      onClick={() => {
                        const isValidate = validateSelectForm(values);
                        if (isValidate) {
                          submitForm();
                        }
                      }}
                      variant="success"
                      text="Simpan"
                      className="mt-2 px-4"
                      loading={isSubmitting}
                    />
                  </Modal.Footer>
                </form>
              );
            }}
          </Formik>
        )}
      </UpdateModal>
    );
  };

  // DETAIL MODAL
  const DetailModalForm = () => {
    // FORMIK ARRAYHELPER
    const arrayHelperRef = useRef();
    const [isModalLoading, setIsModalLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const [formInitialValues, setFormInitialValues] = useState({
      kode_transaksi: dataModal.kode_transaksi,
      pemeriksa: [],
      pengesah: {},
    });

    const getModalData = () => {
      HakAksesApprovalApi.getSingle(kodeTransaksi)
        .then(({ data }) => {
          // SET PENGESAH
          const pengesah = {
            ...data.data.jabatan_pengesah[0],
            jenis: 'PENGESAH',
            value: data.data.jabatan_pengesah[0].id_jabatan,
            label: findNamaJabatan(data.data.jabatan_pengesah[0].id_jabatan),
          };

          // SET PEMERIKSA
          // SORT PEMERIKSA BERDASARKAN level_jenis ASCENDING
          let pemeriksa = data.data.jabatan_pemeriksa.sort(
            (a, b) => a.level_jenis - b.level_jenis
          );
          // FIND NAMA JABATAN UNTUK SETIAP PEMERIKSA
          pemeriksa.map((val) => {
            val.jenis = 'PEMERIKSA';
            val.value = val.id_jabatan;
            val.label = findNamaJabatan(val.id_jabatan);
          });

          setFormInitialValues({
            kode_transaksi: data.data.kode_transaksi,
            pemeriksa: pemeriksa,
            pengesah: pengesah,
          });
        })
        .catch(() => {
          console.log('Gagal memuat data!');
        })
        .finally(() => setIsModalLoading(false));
    };

    // FUNGSI UNTUK MENCARI NAMA JABATAN BERDASARKAN id_jabatan
    const findNamaJabatan = (id_jabatan) => {
      return (
        dataJabatan.find((val) => val.id_jabatan == id_jabatan)?.nama_jabatan ??
        'Pilih Jabatan'
      );
    };

    useEffect(() => {
      getModalData();

      return () => {
        setIsModalLoading(false);
      };
    }, []);

    return (
      <ReadModal show={isDetailForm} onHide={() => setIsDetailForm(false)}>
        {isModalLoading ? (
          <DataStatus loading={true} text="Memuat" />
        ) : (
          <Formik enableReinitialize initialValues={formInitialValues}>
            {({ values, handleSubmit, setFieldValue }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Modal.Body>
                    <FieldArray
                      name="pemeriksa"
                      render={(arrayHelpers) => {
                        arrayHelperRef.current = arrayHelpers;
                        return (
                          <div>
                            {values.pemeriksa && values.pemeriksa.length > 0 ? (
                              values.pemeriksa.map((pemeriksa, index) => {
                                return (
                                  <div
                                    className="d-flex flex-row justify-content-between align-items-end"
                                    key={index}
                                  >
                                    <div className="col-md-12">
                                      {/* FIELD SELECT PEMERIKSA */}
                                      <SelectSearch
                                        loading={isLoading}
                                        key={index}
                                        label={`Pemeriksa ${index + 1}`}
                                        placeholder={`Pilih Pemeriksa ${
                                          index + 1
                                        }`}
                                        defaultValue={
                                          values.pemeriksa[index] ?? {
                                            value: '',
                                            label: `Pilih Pemeriksa ${
                                              index + 1
                                            }`,
                                          }
                                        }
                                        isDisabled={true}
                                        onChange={(val) => {
                                          arrayHelpers.replace(index, val);
                                        }}
                                        option={dataJabatan.map((val) => {
                                          return {
                                            value: val.id_jabatan,
                                            label: val.nama_jabatan,
                                          };
                                        })}
                                      />
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <div />
                            )}
                          </div>
                        );
                      }}
                    />

                    {/* FIELD SELECT PENYETUJU */}
                    <div className="col-md-12">
                      <SelectSearch
                        name="pengesah"
                        label={'Pengesah'}
                        placeholder="Pilih Pengesah"
                        defaultValue={values.pengesah}
                        isDisabled={true}
                        onChange={(val) => {
                          setFieldValue('pengesah', val);
                        }}
                        option={dataJabatan.map((val) => {
                          return {
                            value: val.id_jabatan,
                            label: val.nama_jabatan,
                          };
                        })}
                      />
                    </div>
                  </Modal.Body>
                  <Modal.Footer />
                </form>
              );
            }}
          </Formik>
        )}
      </ReadModal>
    );
  };

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <THead>
          <Tr>
            <ThFixed className="text-center align-middle p-0">No</ThFixed>
            <Th>Nama Hak Akses Approval</Th>
            <Th>Keterangan</Th>
            {guest ? '' : <Th style={{ width: '15rem' }}>Aksi</Th>}
          </Tr>
        </THead>
        <TBody>
          {dataListHak.map((val, index) => (
            <Tr key={index}>
              <TdFixed>
                {TableNumber(
                  paginationConfig.page,
                  paginationConfig.dataLength,
                  index
                )}
              </TdFixed>
              <Td>{val.keterangan_kode_transaksi}</Td>
              <Td>{val.keterangan}</Td>
              {guest ? (
                ''
              ) : (
                <TdFixed>
                  <div className="d-flex justify-content-around align-items-center">
                    <ActionButton
                      variant="primary"
                      text="Lihat Detail"
                      size="sm"
                      onClick={() => {
                        setKodeTransaksi(val.kode_transaksi);
                        setIsDetailForm(true);
                      }}
                    />
                    <ActionButton
                      variant="warning"
                      text="Ubah Hak Akses"
                      size="sm"
                      onClick={() => {
                        setKodeTransaksi(val.kode_transaksi);
                        setIsEditForm(true);
                      }}
                    />
                  </div>
                </TdFixed>
              )}
            </Tr>
          ))}
        </TBody>
      </Table>
    );

    return (
      <>
        <DataTable />
        <Pagination
          dataLength={paginationConfig.dataLength}
          dataNumber={
            paginationConfig.page * paginationConfig.dataLength -
            paginationConfig.dataLength +
            1
          }
          dataPage={
            paginationConfig.dataCount < paginationConfig.dataLength
              ? paginationConfig.dataCount
              : paginationConfig.page * paginationConfig.dataLength
          }
          dataCount={paginationConfig.dataCount}
          currentPage={paginationConfig.page}
          totalPage={paginationConfig.totalPage}
          onPaginationChange={({ selected }) =>
            setPaginationConfig({
              ...paginationConfig,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPaginationConfig({
              ...paginationConfig,
              page: 1,
              dataLength: e.target.value,
            })
          }
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              placeholder="Cari barang jadi"
              value={searchConfig.key}
              onChange={(e) =>
                setSearchConfig({
                  ...searchConfig,
                  status: true,
                  key: e.target.value,
                })
              }
            />
          </div>
        </CRUDLayout.HeadSearchSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => {
          setAlertConfig({
            ...alertConfig,
            show: false,
          });
        }}
      />

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : dataListHak ? (
        dataListHak.length > 0 ? (
          <PageContent />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      ) : (
        <DataStatus text="Data gagal dimuat" />
      )}
      {isEditForm && <UpdateModalForm />}
      {isDetailForm && <DetailModalForm />}
      {/* <UpdateModalForm />
            <DetailModalForm /> */}
    </CRUDLayout>
  );
};

export default HakAksesApprovalNew;
