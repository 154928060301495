import React from 'react';

const ThFixed = ({ children }) => {
  return (
    <th
      className="p-1 px-2 text-center align-middle text-capitalize"
      style={{
        width: '20px',
        fontSize: '12px',
      }}
    >
      {children}
    </th>
  );
};

export default ThFixed;
