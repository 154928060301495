import Services from '../../../services';

class StatusKaryawanApi {
  get() {
    return Services.get('/hrda/status_pekerja');
  }

  getPage(page, dataLength, key) {
    return Services.get(
      `/hrda/status_pekerja/page/?per_page=${dataLength}&page=${page}&q=${key}`
    );
  }

  search(key) {
    return Services.get(
      '/hrda/status_pekerja/page/?per_page=10&page=1&q=' + key
    );
  }

  show(value) {
    return Services.put('/hrda/status_pekerja/show', value);
  }

  hide(value) {
    return Services.put('/hrda/status_pekerja/hide', value);
  }

  create(value) {
    return Services.post('/hrda/status_pekerja', value);
  }

  update(value) {
    return Services.put('/hrda/status_pekerja', value);
  }

  delete(value) {
    return Services.post('/hrda/status_pekerja/delete', value);
  }

  getDropdownJenis() {
    return Services.get('/hrda/jenis_pekerja');
  }

  getDropdown() {
    return Services.get('/hrda/status_karyawan/dropdown');
  }
}

export default new StatusKaryawanApi();
