import Services from '../../../services';

class PekerjaApi {
  getPage(params) {
    return Services.get(`/hrda/karyawan/page`, { params });
  }

  getSingle(id) {
    return Services.get('/hrda/karyawan/single/?id_karyawan=' + id);
  }

  getJabatan() {
    return Services.get('/hrda/jabatan/dropdown');
  }

  getJenisPegawai() {
    return Services.get('/hrda/karyawan/jenis_pegawai');
  }

  getStatusPegawai(id_jenis_pekerja) {
    return Services.get('/hrda/karyawan/status_pegawai', {
      params: { id_jenis_pekerja: id_jenis_pekerja },
    });
  }

  getStatusPegawaiDetail(id_status_pekerja) {
    return Services.get('/hrda/karyawan/status_pegawai_detail', {
      params: { id_status_pekerja: id_status_pekerja },
    });
  }

  getLokasiKerja() {
    return Services.get(`/hrda/lokasi_kerja/dropdown`);
  }

  getJenjangPendidikan() {
    return Services.get('/hrda/jenjang_pendidikan/dropdown');
  }

  getProvinsi() {
    return Services.get('/hrda/wilayah/dropdown_provinsi');
  }

  getKabupaten(id) {
    return Services.get('/hrda/wilayah/dropdown_kabupaten?id_provinsi=' + id);
  }

  getKecamatan(id) {
    return Services.get('/hrda/wilayah/dropdown_kecamatan?id_kabupaten=' + id);
  }

  getDesa(id) {
    return Services.get('/hrda/wilayah/dropdown_desa?id_kecamatan=' + id);
  }

  getBank() {
    return Services.get('/hrda/bank/dropdown');
  }

  getKeluarga(id) {
    return Services.get('/hrda/karyawan/data_keluarga?id_karyawan=' + id);
  }

  createDataPribadi(values) {
    return Services.post('/hrda/karyawan', values);
  }

  createDataKepegawaian(values) {
    return Services.post('/hrda/karyawan/data_kepegawaian', values);
  }

  createDataKeluarga(values) {
    return Services.post('/hrda/karyawan/data_keluarga', values);
  }

  updateDataAkun(values) {
    return Services.put('/hrda/karyawan/username', values);
  }

  updateDataPribadi(values) {
    return Services.put('/hrda/karyawan', values);
  }

  updateDataKontak(values) {
    return Services.post('/hrda/karyawan/data_kontak', values);
  }

  updateDataAlamat(values) {
    return Services.post('/hrda/karyawan/data_alamat', values);
  }

  updateDataKepegawaian(values) {
    return Services.put('/hrda/karyawan/data_kepegawaian', values);
  }

  updateDataBank(values) {
    return Services.post('/hrda/karyawan/data_bank', values);
  }

  updateDataKeluarga(values) {
    return Services.put('/hrda/karyawan/data_keluarga', values);
  }

  updateDataPendidikan(values) {
    return Services.put('/hrda/karyawan/data_pendidikan', values);
  }

  updateKeluarga(values) {
    return Services.put('/hrda/karyawan/data_keluarga', { ...values });
  }

  deleteKeluarga(values) {
    return Services.post('/hrda/karyawan/data_keluarga_delete', values);
  }

  delete(value) {
    return Services.post('/hrda/karyawan/delete', value);
  }
}

export default new PekerjaApi();
