import {
  IoSpeedometerOutline,
  IoPersonCircleOutline,
  IoSchoolOutline,
  IoPeopleOutline,
  IoKeyOutline,
  IoCalendarOutline,
  IoEaselOutline,
  IoInformationOutline,
  IoCheckboxOutline,
} from 'react-icons/io5';

// IMPORT LOGO
import Logo from '../assets/image/LogoSadhana.png';

// IMPORT PAGE
import {
  // MASTER JABATAN
  Hirarki,
  UnitOrganisasi,
  Pangkat,
  Grade,
  Jabatan,

  // MASTER KARYAWAN
  LokasiKerja,
  JenisKaryawan,
  StatusKaryawan,
  DetailStatusKaryawan,

  // Pekerja
  PekerjaList,
  PekerjaDetail,
  PekerjaCreate,
  PekerjaUpdate,
  PenerimaPettyCashList,

  // MASTER HAK AKSES
  HakAksesAplikasi,
  HakAksesApproval,
  HakAksesApprovalNew,
  HakAksesDivisi,
  HakAksesDashboard,
} from '../pages/HumanResource';

export default {
  LOGO: Logo,
  MODUL: 'HRD Admin',

  /* 
    MENU REQUIREMENTS
      > TEXT  = REQUIRED,
      > LINK  = REQUIRED,
      > EXACT = OPTIONAL (TRUE/FALSE),
      > TYPE  = REQUIRED (MENU/DROPDOWN)
      > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
      > LOGO  = OPTIONAL 
  */

  MENU: [
    {
      text: 'Dashboard',
      link: '/',
      type: 'menu',
      exact: true,
      icon: <IoSpeedometerOutline />,
      hak: ['SUPA', 'HRDA'],
    },
    {
      text: 'Master Jabatan',
      type: 'dropdown',
      icon: <IoSchoolOutline />,
      hak: ['SUPA', 'HRDA'],
      menu: [
        {
          text: 'Hirarki Unit Organisasi',
          link: '/human-resource/master/jabatan/hirarki',
          hak: ['SUPA', 'HRDA', 'HRDA_JBT_HIR'],
        },
        {
          text: 'Unit Organisasi',
          link: '/human-resource/master/jabatan/unit-organisasi',
          hak: ['SUPA', 'HRDA', 'HRDA_JBT_UOR'],
        },
        {
          text: 'Pangkat',
          link: '/human-resource/master/jabatan/pangkat',
          hak: ['SUPA', 'HRDA', 'HRDA_JBT_PAN'],
        },
        {
          text: 'Grade',
          link: '/human-resource/master/jabatan/grade',
          hak: ['SUPA', 'HRDA', 'HRDA_JBT_GRD'],
        },
        {
          text: 'Jabatan',
          link: '/human-resource/master/jabatan/jabatan',
          hak: ['SUPA', 'HRDA', 'HRDA_JBT_JAB'],
        },
      ],
    },
    {
      text: 'Master Pekerja',
      type: 'dropdown',
      icon: <IoPeopleOutline />,
      hak: ['SUPA', 'HRDA'],
      menu: [
        {
          text: 'Lokasi Kerja',
          link: '/human-resource/master/karyawan/lokasi-kerja',
          hak: ['SUPA', 'HRDA', 'HRDA_KRY_LOK'],
        },
        {
          text: 'Jenis Pekerja',
          link: '/human-resource/master/karyawan/jenis',
          hak: ['SUPA', 'HRDA', 'SUPA', 'HRDA_KRY_JK'],
        },
        {
          text: 'Status Pekerja',
          link: '/human-resource/master/karyawan/status',
          hak: ['SUPA', 'HRDA', 'SUPA', 'HRDA_KRY_STA'],
        },
        {
          text: 'Detail Status Pekerja',
          link: '/human-resource/master/karyawan/detail-status',
          hak: ['SUPA', 'HRDA', 'SUPA', 'HRDA_KRY_SKD'],
        },
        {
          text: 'Pekerja',
          link: '/human-resource/master/pekerja',
          hak: ['SUPA', 'HRDA', 'HRDA_KRY_KRY'],
        },
      ],
    },
    {
      text: 'Hak Akses',
      type: 'dropdown',
      icon: <IoKeyOutline />,
      hak: ['SUPA', 'HRDA'],
      menu: [
        {
          text: 'Hak Akses Modul',
          link: '/human-resource/master/hak/divisi',
          hak: ['SUPA', 'HRDA', 'HRDA_HAK_DIV'],
        },
        {
          text: 'Hak Akses Approval',
          link: '/human-resource/master/hak/approval',
          hak: ['SUPA', 'HRDA', 'HRDA_HAK_APR'],
        },
        {
          text: 'Hak Akses Aplikasi',
          link: '/human-resource/master/hak/aplikasi',
          hak: ['SUPA', 'HRDA', 'HRDA_HAK_APP'],
        },
        {
          text: 'Hak Akses Dashboard',
          link: '/human-resource/master/hak/dashboard',
          hak: ['SUPA', 'HRDA', 'HRDA_HAK_DAS'],
        },
        {
          text: 'Penerima Petty Cash',
          link: '/human-resource/master/hak/penerima-petty-cash',
          hak: ['SUPA', 'HRDA', 'HRDA_TRN_PPC'],
        },
      ],
    },
    // {
    //   text: "Laporan",
    //   type: "dropdown",
    //   icon: <IoDocumentOutline />,
    //   hak: ["HRDA"],
    //   menu: [],
    // },
    {
      text: 'Absensi',
      link: '/human-resource/absensi',
      type: 'menu',
      exact: true,
      icon: <IoCheckboxOutline />,
      hak: ['SUPA', 'HRDA'],
    },
    {
      text: 'Training',
      link: '/human-resource/training',
      type: 'menu',
      exact: true,
      icon: <IoEaselOutline />,
      hak: ['SUPA', 'HRDA'],
    },
    {
      text: 'Info Karyawan',
      link: '/human-resource/info-karyawan',
      type: 'menu',
      exact: true,
      icon: <IoInformationOutline />,
      hak: ['SUPA', 'HRDA'],
    },
    {
      text: 'Agenda',
      link: '/human-resource/agenda',
      type: 'menu',
      exact: true,
      icon: <IoCalendarOutline />,
      hak: ['SUPA', 'HRDA'],
    },
    {
      text: 'Profil',
      type: 'dropdown',
      icon: <IoPersonCircleOutline />,
      hak: ['SUPA', 'ANG'],
      menu: [
        {
          text: 'Akun Saya',
          link: '/profil',
          hak: ['SUPA', 'ANG'],
        },
        {
          text: 'Ubah Akun',
          link: '/profil/ubah',
          hak: ['SUPA', 'ANG'],
        },
        {
          text: 'Ganti Password',
          link: '/profil/ganti-password',
          hak: ['SUPA', 'ANG'],
        },
      ],
    },
  ],

  ROUTES: [
    {
      exact: true,
      path: '/human-resource/master/jabatan/hirarki',
      page: Hirarki,
      hak: ['HRDA', 'HRDA_JBT_HIR'],
    },
    {
      exact: true,
      path: '/human-resource/master/jabatan/unit-organisasi',
      page: UnitOrganisasi,
      hak: ['HRDA', 'HRDA_JBT_UOR'],
    },
    {
      exact: true,
      path: '/human-resource/master/jabatan/pangkat',
      page: Pangkat,
      hak: ['HRDA', 'HRDA_JBT_PAN'],
    },
    {
      exact: true,
      path: '/human-resource/master/jabatan/grade',
      page: Grade,
      hak: ['HRDA', 'HRDA_JBT_GRD'],
    },
    {
      exact: true,
      path: '/human-resource/master/jabatan/jabatan',
      page: Jabatan,
      hak: ['HRDA', 'HRDA_JBT_JBT'],
    },

    {
      exact: true,
      path: '/human-resource/master/karyawan/lokasi-kerja',
      page: LokasiKerja,
      hak: ['HRDA', 'HRDA_KRY_LOK'],
    },
    {
      exact: true,
      path: '/human-resource/master/karyawan/jenis',
      page: JenisKaryawan,
      hak: ['HRDA', 'HRDA_KRY_JK', 'SUPA'],
    },
    {
      exact: true,
      path: '/human-resource/master/karyawan/status',
      page: StatusKaryawan,
      hak: ['HRDA', 'HRDA_KRY_STA', 'SUPA'],
    },
    {
      exact: true,
      path: '/human-resource/master/karyawan/detail-status',
      page: DetailStatusKaryawan,
      hak: ['HRDA', 'HRDA_KRY_SKD', 'SUPA'],
    },

    // Pekerja
    {
      exact: true,
      path: '/human-resource/master/pekerja',
      page: PekerjaList,
      hak: ['HRDA', 'HRDA_KRY_KRY'],
    },
    {
      exact: true,
      path: '/human-resource/master/pekerja/tambah',
      page: PekerjaCreate,
      hak: ['HRDA', 'HRDA_KRY_KRY'],
    },
    {
      exact: true,
      path: '/human-resource/master/pekerja/detail/:id',
      page: PekerjaDetail,
      hak: ['HRDA', 'HRDA_KRY_KRY'],
    },
    {
      exact: true,
      path: '/human-resource/master/pekerja/ubah/:id',
      page: PekerjaUpdate,
      hak: ['HRDA', 'HRDA_KRY_KRY'],
    },

    {
      exact: true,
      path: '/human-resource/master/hak/aplikasi',
      page: HakAksesAplikasi,
      hak: ['HRDA', 'HRDA_HAK_APP'],
    },
    {
      exact: true,
      path: '/human-resource/master/hak/approval',
      page: HakAksesApprovalNew,
      hak: ['HRDA', 'HRDA_HAK_APR'],
    },
    {
      exact: true,
      path: '/human-resource/master/hak/divisi',
      page: HakAksesDivisi,
      hak: ['HRDA', 'HRDA_HAK_DIV'],
    },
    {
      exact: true,
      path: '/human-resource/master/hak/dashboard',
      page: HakAksesDashboard,
      hak: ['HRDA', 'HRDA_HAK_DAS'],
    },

    // Penerima Petty Cash
    {
      exact: true,
      path: '/human-resource/master/hak/penerima-petty-cash',
      page: PenerimaPettyCashList,
      hak: ['HRDA', 'HRDA_TRN_PPC'],
    },
  ],
};
