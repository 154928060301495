import React from 'react';
import Alert from 'react-bootstrap/Alert';

const ErrorAlert = ({ textError }) => {
  return (
    <Alert variant="danger">
      {Array.isArray(textError) ? (
        textError.map((err) => (
          <div>
            <li>{err}</li>
          </div>
        ))
      ) : (
        <div>
          <li>{textError}</li>
        </div>
      )}
    </Alert>
  );
};

export default ErrorAlert;
