import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import {
  Input,
  SelectSearch,
  Alert,
  ActionButton,
  InfoItemVertical,
} from 'components';
import { PekerjaApi } from 'api';

export const SectionDataAlamat = ({
  action,
  onSubmit,
  submitLoading,
  alert,
}) => {
  const { values, errors, touched, handleChange, setFieldValue, setValues } =
    useFormikContext();
  const [dataProvinsi, setDataProvinsi] = useState({
    loading: false,
    error: false,
    data: [],
  });
  const [dataKabupatenAsal, setDataKabupatenAsal] = useState({
    loading: false,
    error: false,
    data: [],
  });
  const [dataKecamatanAsal, setDataKecamatanAsal] = useState({
    loading: false,
    error: false,
    data: [],
  });
  const [dataDesaAsal, setDataDesaAsal] = useState({
    loading: false,
    error: false,
    data: [],
  });
  const [dataKabupatenSaatIni, setDataKabupatenSaatIni] = useState({
    loading: false,
    error: false,
    data: [],
  });
  const [dataKecamatanSaatIni, setDataKecamatanSaatIni] = useState({
    loading: false,
    error: false,
    data: [],
  });
  const [dataDesaSaatIni, setDataDesaSaatIni] = useState({
    loading: false,
    error: false,
    data: [],
  });

  const getDataProvinsi = () => {
    setDataProvinsi({ loading: true, error: false, data: [] });

    PekerjaApi.getProvinsi()
      .then((res) => {
        setDataProvinsi({
          loading: false,
          error: false,
          data: res?.data?.data?.map((val) => ({
            label: val?.nama_provinsi,
            value: val?.id_provinsi,
          })),
        });
      })
      .catch((err) => {
        setDataProvinsi({
          loading: false,
          error: true,
          data: [],
        });
      });
  };

  const getDataKabupaten = (id_provinsi, type) => {
    type === 'asal'
      ? setDataKabupatenAsal({ loading: true, error: false, data: [] })
      : setDataKabupatenSaatIni({ loading: true, error: false, data: [] });

    PekerjaApi.getKabupaten(id_provinsi)
      .then((res) => {
        type === 'asal'
          ? setDataKabupatenAsal({
              loading: false,
              error: false,
              data: res?.data?.data?.map((val) => ({
                label: val?.nama_kabupaten,
                value: val?.id_kabupaten,
              })),
            })
          : setDataKabupatenSaatIni({
              loading: false,
              error: false,
              data: res?.data?.data?.map((val) => ({
                label: val?.nama_kabupaten,
                value: val?.id_kabupaten,
              })),
            });
      })
      .catch((err) => {
        type === 'asal'
          ? setDataKabupatenAsal({
              loading: false,
              error: true,
              data: [],
            })
          : setDataKabupatenSaatIni({
              loading: false,
              error: true,
              data: [],
            });
      });
  };

  const getDataKecamatan = (id_kabupaten, type) => {
    type === 'asal'
      ? setDataKecamatanAsal({ loading: true, error: false, data: [] })
      : setDataKecamatanSaatIni({ loading: true, error: false, data: [] });

    PekerjaApi.getKecamatan(id_kabupaten)
      .then((res) => {
        type === 'asal'
          ? setDataKecamatanAsal({
              loading: false,
              error: false,
              data: res?.data?.data?.map((val) => ({
                label: val?.nama_kecamatan,
                value: val?.id_kecamatan,
              })),
            })
          : setDataKecamatanSaatIni({
              loading: false,
              error: false,
              data: res?.data?.data?.map((val) => ({
                label: val?.nama_kecamatan,
                value: val?.id_kecamatan,
              })),
            });
      })
      .catch((err) => {
        type === 'asal'
          ? setDataKecamatanAsal({
              loading: false,
              error: true,
              data: [],
            })
          : setDataKecamatanSaatIni({
              loading: false,
              error: true,
              data: [],
            });
      });
  };

  const getDataDesa = (id_desa, type) => {
    type === 'asal'
      ? setDataDesaAsal({ loading: true, error: false, data: [] })
      : setDataDesaSaatIni({ loading: true, error: false, data: [] });

    PekerjaApi.getDesa(id_desa)
      .then((res) => {
        type === 'asal'
          ? setDataDesaAsal({
              loading: false,
              error: false,
              data: res?.data?.data?.map((val) => ({
                label: val?.nama_desa,
                value: val?.id_desa,
              })),
            })
          : setDataDesaSaatIni({
              loading: false,
              error: false,
              data: res?.data?.data?.map((val) => ({
                label: val?.nama_desa,
                value: val?.id_desa,
              })),
            });
      })
      .catch((err) => {
        type === 'asal'
          ? setDataDesaAsal({
              loading: false,
              error: true,
              data: [],
            })
          : setDataDesaSaatIni({
              loading: false,
              error: true,
              data: [],
            });
      });
  };

  useEffect(() => {
    getDataProvinsi();
    values.id_provinsi_asal &&
      getDataKabupaten(values.id_provinsi_asal, 'asal');
    values.id_kabupaten_asal &&
      getDataKecamatan(values.id_kabupaten_asal, 'asal');
    values.id_kecamatan_asal && getDataDesa(values.id_kecamatan_asal, 'asal');
    values.id_provinsi_saat_ini &&
      getDataKabupaten(values.id_provinsi_saat_ini, 'saat_ini');
    values.id_kabupaten_saat_ini &&
      getDataKecamatan(values.id_kabupaten_saat_ini, 'saat_ini');
    values.id_kecamatan_saat_ini &&
      getDataDesa(values.id_kecamatan_saat_ini, 'saat_ini');
  }, []);

  if (action === 'READ') {
    return (
      <Card>
        <Card.Header>
          <b>Data Alamat</b>
        </Card.Header>
        <Card.Body>
          {/* Data Alamat Asal */}
          <Row>
            <Col lg>
              <InfoItemVertical
                label="Provinsi Asal"
                text={values.nama_provinsi_asal ?? '-'}
              />
            </Col>
            <Col lg>
              <InfoItemVertical
                label="Kabupaten Asal"
                text={values.nama_kabupaten_asal ?? '-'}
              />
            </Col>
          </Row>
          <Row>
            <Col lg>
              <InfoItemVertical
                label="Kecamatan Asal"
                text={values.nama_kecamatan_asal ?? '-'}
              />
            </Col>
            <Col lg>
              <InfoItemVertical
                label="Desa Asal"
                text={values.nama_desa_asal ?? '-'}
              />
            </Col>
          </Row>
          <Row>
            <Col lg>
              <InfoItemVertical
                label="Alamat Asal"
                text={values.alamat_asal ?? '-'}
              />
            </Col>
            <Col lg>
              <InfoItemVertical
                label="Kode Pos Asal"
                text={values.kode_pos_asal ?? '-'}
              />
            </Col>
          </Row>

          <hr />

          {/* Data Alamat Saat Ini */}
          <Row>
            <Col lg>
              <InfoItemVertical
                label="Provinsi Saat Ini"
                text={values.nama_provinsi_saat_ini ?? '-'}
              />
            </Col>
            <Col lg>
              <InfoItemVertical
                label="Kabupaten Saat Ini"
                text={values.nama_kabupaten_saat_ini ?? '-'}
              />
            </Col>
          </Row>
          <Row>
            <Col lg>
              <InfoItemVertical
                label="Kecamatan Saat Ini"
                text={values.nama_kecamatan_saat_ini ?? '-'}
              />
            </Col>
            <Col lg>
              <InfoItemVertical
                label="Desa Saat Ini"
                text={values.nama_desa_saat_ini ?? '-'}
              />
            </Col>
          </Row>
          <Row>
            <Col lg>
              <InfoItemVertical
                label="Alamat Saat Ini"
                text={values.alamat_saat_ini ?? '-'}
              />
            </Col>
            <Col lg>
              <InfoItemVertical
                label="Kode Pos Saat Ini"
                text={values.kode_pos_saat_ini ?? '-'}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card>
      <Card.Header>
        <b>Data Alamat</b>
      </Card.Header>
      <Card.Body>
        <Alert show={alert?.show} variant={alert?.variant} text={alert?.text} />

        {/* Data Alamat Asal */}
        <Row>
          <Col lg>
            <SelectSearch
              label="Provinsi Asal"
              loading={dataProvinsi.loading}
              defaultValue={dataProvinsi.data.find(
                (val) => val.value === values.id_provinsi_asal
              )}
              option={dataProvinsi.data}
              onChange={(val) => {
                setValues({
                  ...values,
                  id_provinsi_asal: val.value,
                  id_kabupaten_asal: '',
                  id_kecamatan_asal: '',
                  id_desa_asal: '',
                });
                getDataKabupaten(val.value, 'asal');
              }}
              errorFetch={dataProvinsi.error}
              error={
                errors.id_provinsi_asal && touched.id_provinsi_asal && true
              }
              errorText={
                errors.id_provinsi_asal &&
                touched.id_provinsi_asal &&
                errors.id_provinsi_asal
              }
            />
          </Col>
          <Col lg>
            <SelectSearch
              isDisabled={!values.id_provinsi_asal}
              label="Kabupaten Asal"
              loading={dataKabupatenAsal.loading}
              defaultValue={dataKabupatenAsal.data.find(
                (val) => val.value === values.id_kabupaten_asal
              )}
              option={dataKabupatenAsal.data}
              onChange={(val) => {
                setValues({
                  ...values,
                  id_kabupaten_asal: val.value,
                  id_kecamatan_asal: '',
                  id_desa_asal: '',
                });
                getDataKecamatan(val.value, 'asal');
              }}
              errorFetch={dataKabupatenAsal.error}
              error={
                errors.id_kabupaten_asal && touched.id_kabupaten_asal && true
              }
              errorText={
                errors.id_kabupaten_asal &&
                touched.id_kabupaten_asal &&
                errors.id_kabupaten_asal
              }
            />
          </Col>
        </Row>
        <Row>
          <Col lg>
            <SelectSearch
              isDisabled={!values.id_kabupaten_asal}
              label="Kecamatan Asal"
              loading={dataKecamatanAsal.loading}
              defaultValue={dataKecamatanAsal.data.find(
                (val) => val.value === values.id_kecamatan_asal
              )}
              option={dataKecamatanAsal.data}
              onChange={(val) => {
                setValues({
                  ...values,
                  id_kecamatan_asal: val.value,
                  id_desa_asal: '',
                });
                getDataDesa(val.value, 'asal');
              }}
              errorFetch={dataKecamatanAsal.error}
              error={
                errors.id_kecamatan_asal && touched.id_kecamatan_asal && true
              }
              errorText={
                errors.id_kecamatan_asal &&
                touched.id_kecamatan_asal &&
                errors.id_kecamatan_asal
              }
            />
          </Col>
          <Col lg>
            <SelectSearch
              isDisabled={!values.id_kecamatan_asal}
              label="Desa Asal"
              loading={dataDesaAsal.loading}
              defaultValue={dataDesaAsal.data.find(
                (val) => val.value === values.id_desa_asal
              )}
              option={dataDesaAsal.data}
              onChange={(val) => {
                setValues({
                  ...values,
                  id_desa_asal: val.value,
                });
              }}
              errorFetch={dataDesaAsal.error}
              error={errors.id_desa_asal && touched.id_desa_asal && true}
              errorText={
                errors.id_desa_asal &&
                touched.id_desa_asal &&
                errors.id_desa_asal
              }
            />
          </Col>
        </Row>
        <Row>
          <Col lg>
            <Input
              type="text"
              label="Alamat Asal"
              placeholder="Masukan alamat asal"
              name="alamat_asal"
              value={values.alamat_asal}
              error={errors.alamat_asal && touched.alamat_asal && true}
              errorText={
                errors.alamat_asal && touched.alamat_asal && errors.alamat_asal
              }
              onChange={handleChange}
            />
          </Col>
          <Col lg>
            <Input
              type="text"
              label="Kode Pos"
              placeholder="Masukan kode pos asal"
              name="kode_pos_asal"
              value={values.kode_pos_asal}
              error={errors.kode_pos_asal && touched.kode_pos_asal && true}
              errorText={
                errors.kode_pos_asal &&
                touched.kode_pos_asal &&
                errors.kode_pos_asal
              }
              onChange={handleChange}
            />
          </Col>
        </Row>

        <hr />

        {/* Data Alamat Saat Ini */}
        <Row>
          <Col lg>
            <SelectSearch
              label="Provinsi Saat Ini"
              loading={dataProvinsi.loading}
              defaultValue={dataProvinsi.data.find(
                (val) => val.value === values.id_provinsi_saat_ini
              )}
              option={dataProvinsi.data}
              onChange={(val) => {
                setValues({
                  ...values,
                  id_provinsi_saat_ini: val.value,
                  id_kabupaten_saat_ini: '',
                  id_kecamatan_saat_ini: '',
                  id_desa_saat_ini: '',
                });
                getDataKabupaten(val.value, 'saat_ini');
              }}
              errorFetch={dataProvinsi.error}
              error={
                errors.id_provinsi_saat_ini &&
                touched.id_provinsi_saat_ini &&
                true
              }
              errorText={
                errors.id_provinsi_saat_ini &&
                touched.id_provinsi_saat_ini &&
                errors.id_provinsi_saat_ini
              }
            />
          </Col>
          <Col lg>
            <SelectSearch
              isDisabled={!values.id_provinsi_saat_ini}
              label="Kabupaten Saat Ini"
              loading={dataKabupatenSaatIni.loading}
              defaultValue={dataKabupatenSaatIni.data.find(
                (val) => val.value === values.id_kabupaten_saat_ini
              )}
              option={dataKabupatenSaatIni.data}
              onChange={(val) => {
                setValues({
                  ...values,
                  id_kabupaten_saat_ini: val.value,
                  id_kecamatan_saat_ini: '',
                  id_desa_saat_ini: '',
                });
                getDataKecamatan(val.value, 'saat_ini');
              }}
              errorFetch={dataKabupatenSaatIni.error}
              error={
                errors.id_kabupaten_saat_ini &&
                touched.id_kabupaten_saat_ini &&
                true
              }
              errorText={
                errors.id_kabupaten_saat_ini &&
                touched.id_kabupaten_saat_ini &&
                errors.id_kabupaten_saat_ini
              }
            />
          </Col>
        </Row>
        <Row>
          <Col lg>
            <SelectSearch
              isDisabled={!values.id_kabupaten_saat_ini}
              label="Kecamatan Saat Ini"
              loading={dataKecamatanSaatIni.loading}
              defaultValue={dataKecamatanSaatIni.data.find(
                (val) => val.value === values.id_kecamatan_saat_ini
              )}
              option={dataKecamatanSaatIni.data}
              onChange={(val) => {
                setValues({
                  ...values,
                  id_kecamatan_saat_ini: val.value,
                  id_desa_saat_ini: '',
                });
                getDataDesa(val.value, 'saat_ini');
              }}
              errorFetch={dataKecamatanSaatIni.error}
              error={
                errors.id_kecamatan_saat_ini &&
                touched.id_kecamatan_saat_ini &&
                true
              }
              errorText={
                errors.id_kecamatan_saat_ini &&
                touched.id_kecamatan_saat_ini &&
                errors.id_kecamatan_saat_ini
              }
            />
          </Col>
          <Col lg>
            <SelectSearch
              isDisabled={!values.id_kecamatan_saat_ini}
              label="Desa Saat Ini"
              loading={dataDesaSaatIni.loading}
              defaultValue={dataDesaSaatIni.data.find(
                (val) => val.value === values.id_desa_saat_ini
              )}
              option={dataDesaSaatIni.data}
              onChange={(val) => {
                setValues({
                  ...values,
                  id_desa_saat_ini: val.value,
                });
              }}
              errorFetch={dataDesaSaatIni.error}
              error={
                errors.id_desa_saat_ini && touched.id_desa_saat_ini && true
              }
              errorText={
                errors.id_desa_saat_ini &&
                touched.id_desa_saat_ini &&
                errors.id_desa_saat_ini
              }
            />
          </Col>
        </Row>
        <Row>
          <Col lg>
            <Input
              type="text"
              label="Alamat Saat Ini"
              placeholder="Masukan alamat saat ini"
              name="alamat_saat_ini"
              value={values.alamat_saat_ini}
              error={errors.alamat_saat_ini && touched.alamat_saat_ini && true}
              errorText={
                errors.alamat_saat_ini &&
                touched.alamat_saat_ini &&
                errors.alamat_saat_ini
              }
              onChange={handleChange}
            />
          </Col>
          <Col lg>
            <Input
              type="text"
              label="Kode Pos"
              placeholder="Masukan kode pos saat ini"
              name="kode_pos_saat_ini"
              value={values.kode_pos_saat_ini}
              error={
                errors.kode_pos_saat_ini && touched.kode_pos_saat_ini && true
              }
              errorText={
                errors.kode_pos_saat_ini &&
                touched.kode_pos_saat_ini &&
                errors.kode_pos_saat_ini
              }
              onChange={handleChange}
            />
          </Col>
        </Row>

        {action !== 'READ' && (
          <div className="text-right mt-4">
            <ActionButton
              variant={action === 'UPDATE' ? 'success' : 'primary'}
              text={`${action === 'UPDATE' ? 'Ubah' : 'Simpan'} Data Alamat`}
              onClick={onSubmit}
              loading={submitLoading}
            />
          </div>
        )}
      </Card.Body>
    </Card>
  );
};
