import React, { useState, useEffect } from 'react';
import { Modal, Alert } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';
import {
  CRUDLayout,
  InputSearch,
  CreateButton,
  DeleteButton,
  UpdateButton,
  ActionButton,
  DataStatus,
  CreateModal,
  UpdateModal,
  DeleteModal,
  Input,
  Pagination,
  TextArea,
  Select,
  Switch,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  SelectSearch,
} from '../../../../components';
import {
  JabatanApi,
  UnitOrganisasiApi,
  PangkatApi,
  GradeApi,
} from '../../../../api';
import { TableNumber } from '../../../../utilities';
import { useIsGuest } from 'hooks';

const Jabatan = ({ setNavbarTitle }) => {
  // Title
  const title = 'Jabatan';

  const guest = useIsGuest();

  // indikator pemanggilan data sedang dimuat di server
  const [isPageLoading, setIsPageLoading] = useState(true);

  // indikator apakah sedang melakukan searching
  const [isSearching, setIsSearching] = useState(false);

  // menampung value dari search form
  const [searchKey, setSearchKey] = useState('');

  // menangani modal form tambah
  const [isCreateForm, setIsCreateForm] = useState(false);
  // menangani modal form ubah
  const [isUpdateform, setIsUpdateform] = useState(false);
  // menangani modal hapus data
  const [isDeleteData, setIsDeleteData] = useState(false);

  // data jenis anggaran
  const [dataJabatan, setDataJabatan] = useState([]);
  const [dataUnitOrganisasi, setDataUnitOrganisasi] = useState([]);
  const [dataPangkat, setDataPangkat] = useState([]);
  const [dataGrade, setDataGrade] = useState([]);
  const [kode, setKode] = useState('');
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [dataCount, setDataCount] = useState(0);

  // menampung data yang akan diubah
  const [updateData, setUpdateData] = useState([]);
  // menampung data yang akan dihapus
  const [deleteData, setDeleteData] = useState([]);

  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false);
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
    show: false,
  });

  // request data dari server
  const getData = () => {
    setIsPageLoading(true);

    setIsSearching(false);

    // request data ke server
    Axios.all([
      JabatanApi.getPage(page, dataLength, searchKey),
      UnitOrganisasiApi.getDropdown(),
      PangkatApi.getDropdown(),
      GradeApi.getDropdown(),
      JabatanApi.kode(),
    ])
      .then(
        Axios.spread((jabatan, unit, pangkat, grade, kode) => {
          setDataJabatan(jabatan.data.data);
          setDataUnitOrganisasi(unit.data.data);
          setDataPangkat(pangkat.data.data);
          setDataGrade(grade.data.data);
          setTotalPage(jabatan.data.total_page);
          setDataCount(jabatan.data.data_count);
          setKode(kode.data.data);
        })
      )
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        })
      )
      .finally(() => {
        setIsPageLoading(false);
      });
  };

  // request search data dari server
  const searchData = (e) => {
    // mencegah terjadinya page reload
    e.preventDefault();

    // set loading
    setIsPageLoading(true);

    // nilai dari form search
    const key = searchKey;

    JabatanApi.search(key)
      .then((res) => {
        setDataJabatan(res.data.data);
        setTotalPage(res.data.total_page);
        setAlertConfig({
          variant: 'primary',
          searchKey: key,
        });
      })
      .catch(() => {
        setAlertConfig({
          variant: 'danger',
          text: 'Gagal untuk memuat data',
        });
      })
      .finally(() => {
        setPage(1);
        setIsSearching(true);
        setIsPageLoading(false);
        setShowAlert(true);
      });
  };

  // menangani hide/show data
  const changeDataStatus = (status, id) => {
    setIsPageLoading(true);
    setShowAlert(false);

    const value = {
      id_jabatan: id,
    };

    const onLoadedSuccess = () => {
      setIsSearching(false);
      setAlertConfig({
        show: true,
        variant: 'primary',
        text: 'Ubah status data berhasil',
      });
      setShowAlert(true);
    };

    const onLoadedFailed = () => {
      setIsSearching(false);
      setAlertConfig({
        show: true,
        variant: 'danger',
        text: 'Ubah status data gagal',
      });
      setShowAlert(true);
    };

    status === true
      ? JabatanApi.show(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData())
      : JabatanApi.hide(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData());
  };

  useEffect(() => {
    // set judul di Navbar
    setNavbarTitle(title);

    // jalankan function request data ke server
    getData();

    // menangani pembersihan saat fetching data
    return () => {
      setIsPageLoading(false);
    };
  }, [setNavbarTitle, page, dataLength, searchKey]);

  // modal tambah
  const TambahModal = () => {
    const [alertFormConfig, setAlertFormConfig] = useState({
      data: [],
      show: false,
    });
    // nilai awal form
    const formInitialValues = {
      kode_jabatan: kode,
      nama_jabatan: '',
      id_unit_organisasi: '',
      id_pangkat: '',
      id_grade: '',
      keterangan: '',
    };

    // skema validasi form
    const formValidationSchema = Yup.object().shape({
      kode_jabatan: Yup.string().required('Masukan kode jabatan'),
      nama_jabatan: Yup.string().required('Masukan nama jabatan'),
      id_unit_organisasi: Yup.string().required('Pilih unit organisasi'),
      id_pangkat: Yup.string().required('Pilih pangkat'),
      id_grade: Yup.string().required('Pilih grade'),
    });

    // request tambah data ke server
    const formSubmitHandler = (values) => {
      JabatanApi.create(values)
        .then(() => {
          // konfigurasi alert
          setAlertConfig({
            show: true,
            variant: 'primary',
            text: 'Tambah data berhasil!',
          });
          setIsCreateForm(false);
          getData();
        })

        .catch((err) => {
          setAlertFormConfig({
            data: err.response.data.message,
            show: true,
          });
        })
        .finally(() => {});
    };

    return (
      <CreateModal
        show={isCreateForm}
        onHide={() => setIsCreateForm(false)}
        title={title}
      >
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            handleChange,
            handleSubmit,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Modal.Body>
                  <Alert
                    variant="danger"
                    show={alertFormConfig.show}
                    className="p-1"
                    size="sm"
                  >
                    {alertFormConfig?.data?.map((val) => (
                      <ul className="mb-0">
                        <li
                          style={{ fontSize: 13, textTransform: 'capitalize' }}
                        >
                          {val}
                        </li>
                      </ul>
                    ))}
                  </Alert>
                  <Input
                    readOnly={true}
                    label="Kode Jabatan"
                    placeholder="Masukan kode jabatan"
                    name="kode_jabatan"
                    value={values.kode_jabatan}
                    error={errors.kode_jabatan && touched.kode_jabatan && true}
                    errorText={errors.kode_jabatan}
                    onChange={handleChange}
                  />
                  <Input
                    label="Nama Jabatan"
                    placeholder="Masukan nama jabatan"
                    name="nama_jabatan"
                    value={values.nama_jabatan}
                    error={errors.nama_jabatan && touched.nama_jabatan && true}
                    errorText={errors.nama_jabatan}
                    onChange={handleChange}
                  />
                  <SelectSearch
                    label="Unit Organisasi"
                    name="id_unit_organisasi"
                    defaultValue={{
                      value: '',
                      label: 'Pilih Unit Organisasi',
                    }}
                    option={dataUnitOrganisasi.map((val) => {
                      return {
                        value: val.id_unit_organisasi,
                        label: val.nama_unit_organisasi,
                      };
                    })}
                    error={
                      errors.id_unit_organisasi &&
                      touched.id_unit_organisasi &&
                      true
                    }
                    errorText={errors.id_unit_organisasi}
                    onChange={(val) =>
                      setFieldValue('id_unit_organisasi', val.value)
                    }
                  />
                  <Select
                    label="Pangkat"
                    name="id_pangkat"
                    defaultValue={values.id_pangkat}
                    error={errors.id_pangkat && touched.id_pangkat && true}
                    errorText={errors.id_pangkat}
                    onChange={handleChange}
                  >
                    <option value="">Pilih pangkat</option>
                    {dataPangkat.map((res, index) => (
                      <option key={index} value={res.id_pangkat}>
                        {res.nama_pangkat}
                      </option>
                    ))}
                  </Select>
                  <Select
                    label="Grade"
                    name="id_grade"
                    defaultValue={values.id_grade}
                    error={errors.id_grade && touched.id_grade && true}
                    errorText={errors.id_grade}
                    onChange={handleChange}
                  >
                    <option value="">Pilih grade</option>
                    {dataGrade.map((res, index) => (
                      <option key={index} value={res.id_grade}>
                        {res.nama_grade}
                      </option>
                    ))}
                  </Select>
                  <TextArea
                    label="Keterangan"
                    placeholder="Masukan keterangan"
                    name="keterangan"
                    value={values.keterangan}
                    error={errors.keterangan && touched.keterangan && true}
                    errorText={errors.keterangan}
                    onChange={handleChange}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <ActionButton
                    type="submit"
                    variant="primary"
                    text="Tambah Data"
                    loading={isSubmitting}
                  />
                </Modal.Footer>
              </form>
            );
          }}
        </Formik>
      </CreateModal>
    );
  };

  // modal ubah
  const UbahModal = () => {
    const [alertFormConfig, setAlertFormConfig] = useState({
      data: [],
      show: false,
    });

    // nilai awal form
    const formInitialValues = {
      kode_jabatan: updateData.kode_jabatan,
      nama_jabatan: updateData.nama_jabatan,
      id_unit_organisasi: updateData.id_unit_organisasi,
      nama_unit_organisasi: updateData.nama_unit_organisasi,
      id_pangkat: updateData.id_pangkat,
      id_grade: updateData.id_grade,
      keterangan: updateData.keterangan,
      id_jabatan: updateData.id_jabatan,
    };

    // skema validasi form
    const formValidationSchema = Yup.object().shape({
      kode_jabatan: Yup.string().required('Masukan kode jabatan'),
      nama_jabatan: Yup.string().required('Masukan nama jabatan'),
      id_unit_organisasi: Yup.string().required('Pilih unit organisasi'),
      id_pangkat: Yup.string().required('Pilih pangkat'),
      id_grade: Yup.string().required('Pilih grade'),
    });
    // request ubah data ke server
    const formSubmitHandler = (values) => {
      JabatanApi.update(values)
        .then(() => {
          // konfigurasi alert
          setAlertConfig({
            show: true,
            variant: 'primary',
            text: 'Tambah data berhasil!',
          });
          setIsUpdateform(false);
          getData();
        })

        .catch((err) => {
          setAlertFormConfig({
            data: err.response.data.message,
            show: true,
          });
        })
        .finally(() => {});
    };

    return (
      <UpdateModal
        show={isUpdateform}
        onHide={() => setIsUpdateform(false)}
        title={title}
      >
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Alert
                  variant="danger"
                  show={alertFormConfig.show}
                  className="p-1"
                  size="sm"
                >
                  {alertFormConfig?.data?.map((val) => (
                    <ul className="mb-0">
                      <li style={{ fontSize: 13, textTransform: 'capitalize' }}>
                        {val}
                      </li>
                    </ul>
                  ))}
                </Alert>
                <Input
                  readOnly={true}
                  label="Kode Jabatan"
                  placeholder="Masukan kode jabatan"
                  name="kode_jabatan"
                  value={values.kode_jabatan}
                  error={errors.kode_jabatan && touched.kode_jabatan && true}
                  errorText={errors.kode_jabatan}
                  onChange={handleChange}
                />
                <Input
                  label="Nama Jabatan"
                  placeholder="Masukan nama jabatan"
                  name="nama_jabatan"
                  value={values.nama_jabatan}
                  error={errors.nama_jabatan && touched.nama_jabatan && true}
                  errorText={errors.nama_jabatan}
                  onChange={handleChange}
                />
                <SelectSearch
                  label="Unit Organisasi"
                  name="id_unit_organisasi"
                  defaultValue={{
                    value: values.id_unit_organisasi,
                    label: values.nama_unit_organisasi,
                  }}
                  option={dataUnitOrganisasi.map((val, index) => {
                    return {
                      value: val.id_unit_organisasi,
                      label: val.nama_unit_organisasi,
                    };
                  })}
                  error={
                    errors.id_unit_organisasi &&
                    touched.id_unit_organisasi &&
                    true
                  }
                  errorText={errors.id_unit_organisasi}
                  onChange={(val) =>
                    setFieldValue('id_unit_organisasi', val.value)
                  }
                />
                <Select
                  label="Pangkat"
                  name="id_pangkat"
                  defaultValue={values.id_pangkat}
                  error={errors.id_pangkat && touched.id_pangkat && true}
                  errorText={errors.id_pangkat}
                  onChange={handleChange}
                >
                  <option value="">Pilih pangkat</option>
                  {dataPangkat.map((res, index) => (
                    <option key={index} value={res.id_pangkat}>
                      {res.nama_pangkat}
                    </option>
                  ))}
                </Select>
                <Select
                  label="Grade"
                  name="id_grade"
                  defaultValue={values.id_grade}
                  error={errors.id_grade && touched.id_grade && true}
                  errorText={errors.id_grade}
                  onChange={handleChange}
                >
                  <option value="">Pilih grade</option>
                  {dataGrade.map((res, index) => (
                    <option key={index} value={res.id_grade}>
                      {res.nama_grade}
                    </option>
                  ))}
                </Select>
                <TextArea
                  label="Keterangan"
                  placeholder="Masukan keterangan"
                  name="keterangan"
                  value={values.keterangan}
                  error={errors.keterangan && touched.keterangan && true}
                  errorText={errors.keterangan}
                  onChange={handleChange}
                />
              </Modal.Body>
              <Modal.Footer>
                <ActionButton
                  type="submit"
                  variant="success"
                  text="Ubah Data"
                  loading={isSubmitting}
                />
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </UpdateModal>
    );
  };

  // modal hapus
  const HapusModal = () => {
    // set id dari data yang ingin dihapus
    const idData = deleteData.id_jabatan;
    const values = { id_jabatan: idData };

    // menangani delete button loading
    const [btnLoading, setBtnLoading] = useState(false);

    // menangani pembersihan saat fetching data
    useEffect(() => {
      return () => {
        setBtnLoading(false);
      };
    }, []);

    // request hapus data ke server
    const deleteDataHandler = () => {
      // set delete button loading
      setBtnLoading(true);

      JabatanApi.delete(values)
        .then(() => {
          // konfigurasi alert
          setAlertConfig({
            show: true,
            variant: 'primary',
            text: 'Hapus data berhasil!',
          });
        })
        .catch(() => {
          // konfigurasi alert
          setAlertConfig({
            show: true,
            variant: 'danger',
            text: `Hapus data gagal!`,
          });
        })
        .finally(() => {
          // menutup modal
          setIsDeleteData(false);
          // menampilkan alert
          setShowAlert(true);
          // request data baru ke server
          getData();
        });
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}
      >
        <span>Kode Jabatan : {deleteData.kode_jabatan}</span>
        <br></br>
        <span>Nama Jabatan : {deleteData.nama_jabatan}</span>
      </DeleteModal>
    );
  };

  // Tabel
  const Table = () => (
    <>
      <CRUDLayout.Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            {guest ? '' : <ThFixed>Aksi</ThFixed>}

            <ThFixed>Kode Jabatan</ThFixed>
            <Th>Nama Jabatan</Th>
            <Th>Unit Organisasi</Th>
            <Th>Pangkat</Th>
            <Th>Grade</Th>
            <Th>Keterangan</Th>
          </Tr>
        </THead>
        <TBody>
          {dataJabatan.map((val, index) => (
            <Tr key={index}>
              <TdFixed>{TableNumber(page, dataLength, index)}</TdFixed>
              {guest ? (
                ''
              ) : (
                <TdFixed>
                  <div className="d-flex justify-content-center">
                    <UpdateButton
                      onClick={() => {
                        setUpdateData(val);
                        setIsUpdateform(true);
                      }}
                    />
                    <DeleteButton
                      onClick={() => {
                        setDeleteData(val);
                        setIsDeleteData(true);
                      }}
                    />
                    <Switch
                      id={toString(index + 1)}
                      checked={!val.is_hidden}
                      onChange={() =>
                        changeDataStatus(val.is_hidden, val.id_jabatan)
                      }
                    />
                  </div>
                </TdFixed>
              )}
              <TdFixed>{val.kode_jabatan}</TdFixed>
              <Td>{val.nama_jabatan}</Td>
              <Td>{val.nama_unit_organisasi}</Td>
              <Td>{val.nama_pangkat}</Td>
              <Td>{val.nama_grade}</Td>
              <Td>{val.keterangan}</Td>
            </Tr>
          ))}
        </TBody>
      </CRUDLayout.Table>
      {!isSearching && (
        <Pagination
          dataLength={dataLength}
          dataNumber={page * dataLength - dataLength + 1}
          dataPage={dataCount < dataLength ? dataCount : page * dataLength}
          dataCount={dataCount}
          onDataLengthChange={(e) => {
            setDataLength(e.target.value);
            setPage(1);
          }}
          currentPage={page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) => setPage(selected + 1)}
        />
      )}
    </>
  );

  return (
    <CRUDLayout>
      {/* head */}
      <CRUDLayout.Head>
        {/* search section */}
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={(e) => {
                const key = e.target.value;
                setSearchKey(key);
                setAlertConfig({
                  show: key ? true : false,
                  variant: 'primary',
                  text: 'Hasil dari pencarian: ' + key,
                });
              }}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        {/* button section */}
        <CRUDLayout.HeadButtonSection>
          <CreateButton onClick={() => setIsCreateForm(true)} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* aLert */}
      <Alert
        variant={alertConfig.variant}
        show={alertConfig.show}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      >
        {alertConfig.text}
      </Alert>

      {/* table section */}
      {
        // cek apakah data sedang dimuat (loading)
        isPageLoading === true ? (
          // loading
          <DataStatus loading={true} text="Memuat data..." />
        ) : // Cek apakah ada data
        dataJabatan.length > 0 ? (
          // Ada data
          <Table />
        ) : (
          // Tidak ada data
          <DataStatus text="Tidak ada data" />
        )
      }

      {/* Modal */}
      <TambahModal />
      <UbahModal />
      <HapusModal />
    </CRUDLayout>
  );
};

export default Jabatan;
