import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import {
  CRUDLayout,
  InputSearch,
  CreateButton,
  DeleteButton,
  UpdateButton,
  ActionButton,
  DataStatus,
  CreateModal,
  UpdateModal,
  DeleteModal,
  Alert,
  Input,
  Pagination,
  TextArea,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  SelectSearch,
} from 'components';
import { TableNumber } from 'utilities';
import { DetailStatusKaryawanApi } from 'api';
import { useIsGuest } from 'hooks';

const DetailStatusKaryawan = ({ setNavbarTitle }) => {
  const title = 'Detail Status Pekerja';
  const guest = useIsGuest();

  // indikator pemanggilan data sedang dimuat di server
  const [isPageLoading, setIsPageLoading] = useState(false);

  // indikator apakah sedang melakukan searching
  const [isSearching, setIsSearching] = useState(false);

  // menampung value dari search form
  const [searchKey, setSearchKey] = useState('');

  // menangani modal form tambah
  const [isCreateForm, setIsCreateForm] = useState(false);
  // menangani modal form ubah
  const [isUpdateform, setIsUpdateform] = useState(false);
  // menangani modal hapus data
  const [isDeleteData, setIsDeleteData] = useState(false);

  // data jenis anggaran
  const [data, setData] = useState([]);
  const [dataStatus, setDataStatus] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [dataCount, setDataCount] = useState(0);

  // menampung data yang akan diubah
  const [updateData, setUpdateData] = useState([]);
  // menampung data yang akan dihapus
  const [deleteData, setDeleteData] = useState([]);
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
    searchKey: '',
  });

  // request data dari server
  const getData = () => {
    setIsPageLoading(true);
    setIsSearching(false);

    // request data ke server
    axios
      .all([
        DetailStatusKaryawanApi.getPage(page, dataLength, searchKey),
        DetailStatusKaryawanApi.getDropdown(),
      ])
      .then(
        axios.spread((res, status) => {
          setData(res.data.data);
          setTotalPage(res.data.total_page);
          setDataCount(res.data.data_count);
          setDataStatus(status.data.data);
        })
      )
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        })
      )
      .finally(() => {
        setIsPageLoading(false);
      });
  };

  // request search data dari server
  const searchData = (e) => {
    // mencegah terjadinya page reload
    e.preventDefault();

    // set loading
    setIsPageLoading(true);

    // nilai dari form search
    const key = searchKey;

    DetailStatusKaryawanApi.search(key)
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
        setAlertConfig({
          show: true,
          variant: 'primary',
          searchKey: key,
        });
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Gagal untuk memuat data',
        });
      })
      .finally(() => {
        setPage(1);
        setIsSearching(true);
        setIsPageLoading(false);
      });
  };

  useEffect(() => {
    // set judul di Navbar
    setNavbarTitle(title);

    // jalankan function request data ke server
    getData();

    // menangani pembersihan saat fetching data
    return () => {
      setIsPageLoading(false);
    };
  }, [setNavbarTitle, page, dataLength, searchKey]);

  const formInitialValues = {
    id_status_pekerja_detail: updateData.id_status_pekerja_detail,
    id_status_pekerja: updateData.id_status_pekerja,
    nama_status_pekerja: updateData.nama_status_pekerja,
    nama_status_pekerja_detail: updateData.nama_status_pekerja_detail,
  };

  // skema validasi form
  const formValidationSchema = Yup.object().shape({
    id_status_pekerja: Yup.string().required('Status Pekerja wajib diisi'),
    nama_status_pekerja_detail: Yup.string().required(
      'Nama Detail Status Pekerja wajib diisi'
    ),
  });

  // modal tambah
  const TambahModal = ({ isCreateForm, setIsCreateForm }) => {
    const [formAlertConfig, setFormAlertConfig] = useState({
      show: false,
      variant: 'danger',
      text: '',
    });

    const formSubmitHandler = (values, { setSubmitting }) => {
      DetailStatusKaryawanApi.create(values)
        .then((res) => {
          console.log(res);
          // konfigurasi alert
          setAlertConfig({
            show: true,
            variant: 'primary',
            text: 'Tambah data berhasil!',
          });

          setIsCreateForm(false);
          getData();
        })
        .catch(({ response }) => {
          setFormAlertConfig({
            show: true,
            variant: 'danger',
            text: response?.data?.error ?? 'Tambah data gagal!',
          });
          setSubmitting(false);
        });
    };

    useEffect(() => {
      return () =>
        setFormAlertConfig({
          show: false,
          variant: 'danger',
          text: '',
        });
    }, []);

    return (
      <CreateModal
        show={isCreateForm}
        onHide={() => setIsCreateForm(false)}
        title={title}
      >
        {/* Form Alert */}
        <div className="mt-3 mx-3">
          <Alert
            show={formAlertConfig.show}
            variant={formAlertConfig.variant}
            text={
              Array.isArray(formAlertConfig.text) ? (
                <ul>
                  {' '}
                  {formAlertConfig.text.map((err) => (
                    <li>{err}</li>
                  ))}{' '}
                </ul>
              ) : (
                formAlertConfig.text
              )
            }
          />
        </div>

        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <SelectSearch
                  label="Status Pekerja"
                  name="id_status_pekerja"
                  defaultValue={{
                    label: updateData.nama_status_pekerja
                      ? updateData.nama_status_pekerja
                      : 'Pilih Jenis Pekerja',
                    value: updateData.id_status_pekerja
                      ? updateData.id_status_pekerja
                      : '',
                  }}
                  onChange={(val) => {
                    setFieldValue('id_status_pekerja', val.value);
                  }}
                  option={dataStatus.map((val) => {
                    return {
                      value: val.id_status_pekerja,
                      label: val.nama_status_pekerja,
                    };
                  })}
                  error={
                    errors.id_status_pekerja &&
                    touched.id_status_pekerja &&
                    true
                  }
                  errorText={
                    errors.id_status_pekerja &&
                    touched.id_status_pekerja &&
                    errors.id_status_pekerja
                  }
                />

                <Input
                  label="Nama Detail Status Pekerja"
                  name="nama_status_pekerja_detail"
                  value={values.nama_status_pekerja_detail}
                  error={
                    errors.nama_status_pekerja_detail &&
                    touched.nama_status_pekerja_detail &&
                    true
                  }
                  errorText={errors.nama_status_pekerja_detail}
                  onChange={handleChange}
                />
              </Modal.Body>
              <Modal.Footer>
                <ActionButton
                  type="submit"
                  variant="primary"
                  text="Tambah Data"
                  loading={isSubmitting}
                />
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </CreateModal>
    );
  };

  // modal ubah
  const UbahModal = ({ isUpdateform, setIsUpdateform }) => {
    const [formAlertConfig, setFormAlertConfig] = useState({
      show: false,
      variant: 'danger',
      text: '',
      // text: ["error not unique", "error already exists"]
    });

    const formSubmitHandler = (values, { setSubmitting }) => {
      DetailStatusKaryawanApi.update(values)
        .then(() => {
          // konfigurasi alert
          setAlertConfig({
            show: true,
            variant: 'primary',
            text: 'Ubah data berhasil!',
          });
          setIsUpdateform(false);
          getData();
        })
        .catch(({ response }) => {
          console.log({ response });

          setFormAlertConfig({
            show: true,
            variant: 'danger',
            text: response?.data?.error ?? 'Ubah data gagal!',
          });
          setSubmitting(false);
        });
    };

    useEffect(() => {
      return () =>
        setFormAlertConfig({
          show: false,
          variant: 'danger',
          text: '',
        });
    }, []);

    return (
      <UpdateModal
        show={isUpdateform}
        onHide={() => setIsUpdateform(false)}
        title={title}
      >
        {/* Form Alert */}
        <div className="mt-3 mx-3">
          <Alert
            show={formAlertConfig.show}
            variant={formAlertConfig.variant}
            text={
              Array.isArray(formAlertConfig.text) ? (
                <ul>
                  {' '}
                  {formAlertConfig.text.map((err) => (
                    <li>{err}</li>
                  ))}{' '}
                </ul>
              ) : (
                formAlertConfig.text
              )
            }
          />
        </div>

        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <SelectSearch
                  label="Status Pekerja"
                  name="id_status_pekerja"
                  defaultValue={{
                    label: updateData.nama_status_pekerja
                      ? updateData.nama_status_pekerja
                      : 'Pilih Jenis Pekerja',
                    value: updateData.id_status_pekerja
                      ? updateData.id_status_pekerja
                      : '',
                  }}
                  onChange={(val) => {
                    setFieldValue('id_status_pekerja', val.value);
                  }}
                  option={dataStatus.map((val) => {
                    return {
                      value: val.id_status_pekerja,
                      label: val.nama_status_pekerja,
                    };
                  })}
                  error={
                    errors.id_status_pekerja &&
                    touched.id_status_pekerja &&
                    true
                  }
                  errorText={
                    errors.id_status_pekerja &&
                    touched.id_status_pekerja &&
                    errors.id_status_pekerja
                  }
                />

                <Input
                  label="Nama Detail Status Pekerja"
                  name="nama_status_pekerja_detail"
                  value={values.nama_status_pekerja_detail}
                  error={
                    errors.nama_status_pekerja_detail &&
                    touched.nama_status_pekerja_detail &&
                    true
                  }
                  errorText={errors.nama_status_pekerja_detail}
                  onChange={handleChange}
                />
              </Modal.Body>

              <Modal.Footer>
                <ActionButton
                  type="submit"
                  variant="success"
                  text="Ubah Data"
                  loading={isSubmitting}
                />
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </UpdateModal>
    );
  };

  // modal hapus
  const HapusModal = () => {
    // set id dari data yang ingin dihapus
    const values = {
      id_status_pekerja_detail: deleteData.id_status_pekerja_detail,
    };

    // menangani delete button loading
    const [btnLoading, setBtnLoading] = useState(false);

    // menangani pembersihan saat fetching data
    useEffect(() => {
      return () => {
        setBtnLoading(false);
      };
    }, []);

    // request hapus data ke server
    const deleteDataHandler = () => {
      // set delete button loading
      setBtnLoading(true);

      DetailStatusKaryawanApi.delete(values)
        .then(() => {
          // konfigurasi alert
          setAlertConfig({
            show: true,
            variant: 'primary',
            text: 'Hapus data berhasil!',
          });
        })
        .catch((err) => {
          // konfigurasi alert
          setAlertConfig({
            show: true,
            variant: 'danger',
            text: `Hapus data gagal! (${err})`,
          });
        })
        .finally(() => {
          // menutup modal
          setIsDeleteData(false);
          // request data baru ke server
          getData();
        });
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}
      >
        <span>
          Nama Detail Status Pekerja : {deleteData.nama_status_pekerja_detail}
        </span>
      </DeleteModal>
    );
  };

  // Tabel
  const Table = () => (
    <>
      <CRUDLayout.Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            {guest ? '' : <ThFixed>Aksi</ThFixed>}
            <Th>Status Pekerja</Th>
            <Th>Detail Status Pekerja</Th>
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => (
            <Tr key={index}>
              <TdFixed>{TableNumber(page, dataLength, index)}</TdFixed>
              {guest ? (
                ''
              ) : (
                <TdFixed>
                  <div className="d-flex justify-content-center">
                    <UpdateButton
                      onClick={() => {
                        setUpdateData(val);
                        setIsUpdateform(true);
                      }}
                    />
                    <DeleteButton
                      onClick={() => {
                        setDeleteData(val);
                        setIsDeleteData(true);
                      }}
                    />
                  </div>
                </TdFixed>
              )}
              <Td>{val.nama_status_pekerja}</Td>
              <Td>{val.nama_status_pekerja_detail}</Td>
            </Tr>
          ))}
        </TBody>
      </CRUDLayout.Table>
      {!isSearching && (
        <Pagination
          dataLength={dataLength}
          dataNumber={page * dataLength - dataLength + 1}
          dataPage={dataCount < dataLength ? dataCount : page * dataLength}
          dataCount={dataCount}
          onDataLengthChange={(e) => {
            setDataLength(e.target.value);
            setPage(1);
          }}
          currentPage={page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) => setPage(selected + 1)}
        />
      )}
    </>
  );

  return (
    <CRUDLayout>
      {/* head */}
      <CRUDLayout.Head>
        {/* search section */}
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={(e) => {
                const key = e.target.value;
                setSearchKey(key);
                setAlertConfig({
                  show: key ? true : false,
                  variant: 'primary',
                  text: 'Hasil dari pencarian: ' + key,
                });
              }}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        {/* button section */}
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() => {
              setUpdateData({
                id_status_pekerja_detail: '',
                id_status_pekerja: '',
                nama_status_pekerja: '',
                nama_status_pekerja_detail: '',
              });
              setIsCreateForm(true);
            }}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* aLert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* table section */}
      {
        // cek apakah data sedang dimuat (loading)
        isPageLoading === true ? (
          // loading
          <DataStatus loading={true} text="Memuat data..." />
        ) : // Cek apakah ada data
        data.length > 0 ? (
          // Ada data
          <Table />
        ) : (
          // Tidak ada data
          <DataStatus text="Tidak ada data" />
        )
      }

      {/* Modal */}
      {isCreateForm && (
        <TambahModal
          isCreateForm={isCreateForm}
          setIsCreateForm={setIsCreateForm}
        />
      )}
      {isUpdateform && (
        <UbahModal
          isUpdateform={isUpdateform}
          setIsUpdateform={setIsUpdateform}
        />
      )}
      <HapusModal />
    </CRUDLayout>
  );
};

export default DetailStatusKaryawan;
