import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import {
  DatePicker,
  Input,
  SelectSearch,
  Alert,
  ActionButton,
  InfoItemVertical,
} from 'components';
import { PekerjaApi } from 'api';
import { DateConvert } from 'utilities';

export const SectionDataPegawai = ({
  action,
  onSubmit,
  submitLoading,
  alert,
}) => {
  const { values, errors, touched, handleChange, setFieldValue, setValues } =
    useFormikContext();
  const [dataJenisPegawai, setDataJenisPegawai] = useState({
    loading: false,
    error: false,
    data: [],
  });
  const [dataStatusPegawai, setDataStatusPegawai] = useState({
    loading: false,
    error: false,
    data: [],
  });
  const [dataStatusPegawaiDetail, setDataStatusPegawaiDetail] = useState({
    loading: false,
    error: false,
    data: [],
  });
  const [dataJabatan, setDataJabatan] = useState({
    loading: false,
    error: false,
    data: [],
  });
  const [dataLokasiKerja, setDataLokasiKerja] = useState({
    loading: false,
    error: false,
    data: [],
  });

  const getDataJenisPegawai = () => {
    setDataJenisPegawai({ loading: true, error: false, data: [] });

    PekerjaApi.getJenisPegawai()
      .then((res) => {
        setDataJenisPegawai({
          loading: false,
          error: false,
          data: res?.data?.data?.map((val) => ({
            label: val?.nama_jenis_pekerja,
            value: val?.id_jenis_pekerja,
          })),
        });
      })
      .catch((err) => {
        setDataJenisPegawai({
          loading: false,
          error: true,
          data: [],
        });
      });
  };

  const getDataStatusPegawai = (id_jenis_pekerja) => {
    setDataStatusPegawai({ loading: true, error: false, data: [] });

    PekerjaApi.getStatusPegawai(id_jenis_pekerja)
      .then((res) => {
        setDataStatusPegawai({
          loading: false,
          error: false,
          data: res?.data?.data?.map((val) => ({
            label: val?.nama_status_pekerja,
            value: val?.id_status_pekerja,
          })),
        });
      })
      .catch((err) => {
        setDataStatusPegawai({
          loading: false,
          error: true,
          data: [],
        });
      });
  };

  const getDataStatusPegawaiDetail = (id_status_pekerja) => {
    setDataStatusPegawaiDetail({ loading: true, error: false, data: [] });

    PekerjaApi.getStatusPegawaiDetail(id_status_pekerja)
      .then((res) => {
        setDataStatusPegawaiDetail({
          loading: false,
          error: false,
          data: res?.data?.data?.map((val) => ({
            label: val?.nama_status_pekerja_detail,
            value: val?.id_status_pekerja_detail,
          })),
        });
      })
      .catch((err) => {
        setDataStatusPegawaiDetail({
          loading: false,
          error: true,
          data: [],
        });
      });
  };

  const getDataJabatan = () => {
    setDataJabatan({ loading: true, error: false, data: [] });

    PekerjaApi.getJabatan()
      .then((res) => {
        setDataJabatan({
          loading: false,
          error: false,
          data: res?.data?.data?.map((val) => ({
            label: val?.nama_jabatan,
            value: val?.id_jabatan,
          })),
        });
      })
      .catch((err) => {
        setDataJabatan({
          loading: false,
          error: true,
          data: [],
        });
      });
  };

  const getDataLokasiKerja = () => {
    setDataLokasiKerja({ loading: true, error: false, data: [] });

    PekerjaApi.getLokasiKerja()
      .then((res) => {
        setDataLokasiKerja({
          loading: false,
          error: false,
          data: res?.data?.data?.map((val) => ({
            label: val?.nama_lokasi_kerja,
            value: val?.id_lokasi_kerja,
          })),
        });
      })
      .catch((err) => {
        setDataLokasiKerja({
          loading: false,
          error: true,
          data: [],
        });
      });
  };

  useEffect(() => {
    getDataJenisPegawai();
    getDataJabatan();
    getDataLokasiKerja();
    values.id_jenis_pekerja && getDataStatusPegawai(values.id_jenis_pekerja);
    values.id_status_pekerja &&
      getDataStatusPegawaiDetail(values.id_status_pekerja);
  }, []);

  if (action === 'READ') {
    return (
      <Card>
        <Card.Header>
          <b>Data Kepegawaian</b>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col lg>
              <InfoItemVertical
                label="No. NPWP"
                text={values?.no_npwp ?? '-'}
              />
            </Col>
            <Col lg>
              <InfoItemVertical
                label="Jenis Pekerja"
                text={values?.nama_jenis_pekerja ?? '-'}
              />
            </Col>
          </Row>
          <Row>
            <Col lg>
              <InfoItemVertical
                label="Jenis Pekerja"
                text={values?.nama_status_pekerja ?? '-'}
              />
            </Col>

            <Col lg>
              <InfoItemVertical
                label="Status Detail pekerja"
                text={values?.nama_status_pekerja_detail ?? '-'}
              />
            </Col>
          </Row>
          <Row>
            <Col lg>
              <InfoItemVertical
                label="No. Induk Pekerja"
                text={values?.no_karyawan ?? '-'}
              />
            </Col>

            <Col lg>
              <InfoItemVertical
                label="Jabatan"
                text={values?.nama_jabatan ?? '-'}
              />
            </Col>
          </Row>
          <Row>
            <Col md>
              <InfoItemVertical
                label="Lokasi Kerja"
                text={values?.nama_lokasi_kerja ?? '-'}
              />
            </Col>
          </Row>
          <Row>
            <Col lg>
              <InfoItemVertical
                label="Tanggal Bergabung"
                text={
                  values?.tgl_bergabung
                    ? DateConvert(new Date(values.tgl_bergabung)).detail
                    : '-'
                }
              />
            </Col>
            <Col lg>
              <InfoItemVertical
                label="Tanggal Pengangkatan"
                text={
                  values?.tgl_pengangkatan
                    ? DateConvert(new Date(values.tgl_pengangkatan)).detail
                    : '-'
                }
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card>
      <Card.Header>
        <b>Data Kepegawaian</b>
      </Card.Header>
      <Card.Body>
        <Alert show={alert?.show} variant={alert?.variant} text={alert?.text} />
        <Row>
          <Col lg>
            <Input
              type="text"
              label="No. NPWP"
              placeholder="Masukan nomor npwp"
              name="no_npwp"
              value={values.no_npwp}
              error={errors.no_npwp && touched.no_npwp && true}
              errorText={errors.no_npwp && touched.no_npwp && errors.no_npwp}
              onChange={handleChange}
            />
          </Col>
          <Col lg>
            <SelectSearch
              label="Jenis Pekerja"
              loading={dataJenisPegawai.loading}
              defaultValue={dataJenisPegawai.data.find(
                (val) => val.value === values.id_jenis_pekerja
              )}
              option={dataJenisPegawai.data}
              onChange={(val) => {
                setValues({
                  ...values,
                  id_jenis_pekerja: val.value,
                  id_status_pekerja: '',
                  id_status_detail_pekerja: '',
                });
                getDataStatusPegawai(val.value);
              }}
              errorFetch={dataJenisPegawai.error}
              error={
                errors.id_jenis_pekerja && touched.id_jenis_pekerja && true
              }
              errorText={
                errors.id_jenis_pekerja &&
                touched.id_jenis_pekerja &&
                errors.id_jenis_pekerja
              }
            />
          </Col>
        </Row>
        <Row>
          <Col lg>
            <SelectSearch
              isDisabled={!values.id_jenis_pekerja}
              label="Status Pekerja"
              loading={dataStatusPegawai.loading}
              defaultValue={dataStatusPegawai.data.find(
                (val) => val.value === values.id_status_pekerja
              )}
              option={dataStatusPegawai.data}
              onChange={(val) => {
                setValues({
                  ...values,
                  id_status_pekerja: val.value,
                  id_status_detail_pekerja: '',
                });
                getDataStatusPegawaiDetail(val.value);
              }}
              errorFetch={dataStatusPegawai.error}
              error={
                errors.id_status_pekerja && touched.id_status_pekerja && true
              }
              errorText={
                errors.id_status_pekerja &&
                touched.id_status_pekerja &&
                errors.id_status_pekerja
              }
            />
          </Col>

          <Col lg>
            <SelectSearch
              isDisabled={!values.id_status_pekerja}
              label="Status Detail Pekerja"
              loading={dataStatusPegawaiDetail.loading}
              defaultValue={dataStatusPegawaiDetail.data.find(
                (val) => val.value === values.id_status_pekerja_detail
              )}
              option={dataStatusPegawaiDetail.data}
              onChange={(val) => {
                setValues({
                  ...values,
                  id_status_pekerja_detail: val.value,
                });
              }}
              errorFetch={dataStatusPegawaiDetail.error}
              error={
                errors.id_status_pekerja_detail &&
                touched.id_status_pekerja_detail &&
                true
              }
              errorText={
                errors.id_status_pekerja_detail &&
                touched.id_status_pekerja_detail &&
                errors.id_status_pekerja_detail
              }
            />
          </Col>
        </Row>
        <Row>
          <Col lg>
            <Input
              type="text"
              label="No. Induk Pegawai"
              placeholder="Masukan no. induk pegawai"
              name="no_karyawan"
              value={values.no_karyawan}
              onChange={handleChange}
            />
          </Col>

          <Col lg>
            <SelectSearch
              label="Jabatan"
              loading={dataJabatan.loading}
              defaultValue={dataJabatan.data.find(
                (val) => val.value === values.id_jabatan
              )}
              option={dataJabatan.data}
              onChange={(val) => setFieldValue('id_jabatan', val.value)}
              errorFetch={dataJabatan.error}
              error={errors.id_jabatan && touched.id_jabatan && true}
              errorText={
                errors.id_jabatan && touched.id_jabatan && errors.id_jabatan
              }
            />
          </Col>
        </Row>
        <Row>
          <Col md>
            <SelectSearch
              label="Lokasi Kerja"
              loading={dataLokasiKerja.loading}
              defaultValue={dataLokasiKerja.data.find(
                (val) => val.value === values.id_lokasi_kerja
              )}
              option={dataLokasiKerja.data}
              onChange={(val) => setFieldValue('id_lokasi_kerja', val.value)}
              errorFetch={dataLokasiKerja.error}
              error={errors.id_lokasi_kerja && touched.id_lokasi_kerja && true}
              errorText={
                errors.id_lokasi_kerja &&
                touched.id_lokasi_kerja &&
                errors.id_lokasi_kerja
              }
            />
          </Col>
        </Row>
        <Row>
          <Col lg>
            <DatePicker
              label="Tanggal Bergabung"
              placeholderText="Pilih tgl. bergabung"
              selected={
                values.tgl_bergabung
                  ? new Date(values.tgl_bergabung)
                  : undefined
              }
              error={errors.tgl_bergabung && touched.tgl_bergabung && true}
              errorText={
                errors.tgl_bergabung &&
                touched.tgl_bergabung &&
                errors.tgl_bergabung
              }
              onChange={(date) => setFieldValue('tgl_bergabung', date)}
            />
          </Col>
          <Col lg>
            <DatePicker
              label="Tanggal Pengangkatan"
              placeholderText="Pilih tgl. pengangkatan"
              selected={
                values.tgl_pengangkatan
                  ? new Date(values.tgl_pengangkatan)
                  : undefined
              }
              error={
                errors.tgl_pengangkatan && touched.tgl_pengangkatan && true
              }
              errorText={
                errors.tgl_pengangkatan &&
                touched.tgl_pengangkatan &&
                errors.tgl_pengangkatan
              }
              onChange={(date) => setFieldValue('tgl_pengangkatan', date)}
            />
          </Col>
        </Row>

        {action === 'UPDATE' && (
          <div className="text-right mt-4">
            <ActionButton
              variant="success"
              text="Ubah Data Kepegawaian"
              onClick={onSubmit}
              loading={submitLoading}
            />
          </div>
        )}
      </Card.Body>
    </Card>
  );
};
