import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import {
  Input,
  TextArea,
  Alert,
  ActionButton,
  SelectSearch,
  InfoItemVertical,
} from 'components';
import { PekerjaApi } from 'api';

export const SectionDataPerbankan = ({
  action,
  onSubmit,
  submitLoading,
  alert,
}) => {
  const { values, errors, touched, handleChange, setValues } =
    useFormikContext();
  const [dataBank, setDataBank] = useState({
    loading: false,
    error: false,
    data: [],
  });

  const getDataBank = () => {
    setDataBank({ loading: true, error: false, data: [] });

    PekerjaApi.getBank()
      .then((res) => {
        setDataBank({
          loading: false,
          error: false,
          data: res?.data?.data?.map((val) => ({
            label: val?.nama_bank,
            value: val?.kode_bank,
          })),
        });
      })
      .catch((err) => {
        setDataBank({
          loading: false,
          error: true,
          data: [],
        });
      });
  };

  useEffect(() => {
    getDataBank();
  }, []);

  if (action === 'READ') {
    return (
      <Card>
        <Card.Header>
          <b>Data Bank</b>
        </Card.Header>
        <Card.Body>
          <InfoItemVertical label="Bank" text={values?.nama_bank ?? '-'} />
          <InfoItemVertical
            label="No. Rekening"
            text={values?.no_rekening ?? '-'}
          />
          <InfoItemVertical label="Atas Nama" text={values?.atas_nama ?? '-'} />
          <InfoItemVertical label="Cabang" text={values?.cabang ?? '-'} />
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card>
      <Card.Header>
        <b>Data Bank</b>
      </Card.Header>
      <Card.Body>
        <Alert show={alert?.show} variant={alert?.variant} text={alert?.text} />
        <SelectSearch
          label="Bank"
          loading={dataBank.loading}
          defaultValue={dataBank.data.find(
            (val) => val.value === values.kode_bank
          )}
          option={dataBank.data}
          onChange={(val) => {
            setValues({
              ...values,
              kode_bank: val.value,
            });
          }}
          errorFetch={dataBank.error}
          error={errors.kode_bank && touched.kode_bank && true}
          errorText={errors.kode_bank && touched.kode_bank && errors.kode_bank}
        />
        <Input
          type="text"
          label="No. rekening"
          placeholder="Masukan nomor rekening"
          name="no_rekening"
          value={values.no_rekening}
          error={errors.no_rekening && touched.no_rekening && true}
          errorText={
            errors.no_rekening && touched.no_rekening && errors.no_rekening
          }
          onChange={handleChange}
        />
        <Input
          type="text"
          label="Atas Nama"
          placeholder="Masukan atas nama"
          name="atas_nama"
          value={values.atas_nama}
          error={errors.atas_nama && touched.atas_nama && true}
          errorText={errors.atas_nama && touched.atas_nama && errors.atas_nama}
          onChange={handleChange}
        />
        <TextArea
          type="text"
          label="Cabang"
          placeholder="Masukan cabang"
          name="cabang"
          value={values.cabang}
          error={errors.cabang && touched.cabang && true}
          errorText={errors.cabang && touched.cabang && errors.cabang}
          onChange={handleChange}
        />
        {action !== 'READ' && (
          <div className="text-right mt-4">
            <ActionButton
              variant={action === 'UPDATE' ? 'success' : 'primary'}
              text={`${action === 'UPDATE' ? 'Ubah' : 'Simpan'} Data Perbankan`}
              onClick={onSubmit}
              loading={submitLoading}
            />
          </div>
        )}
      </Card.Body>
    </Card>
  );
};
