export const MODAL_TITLE = {
  CREATE: 'Tambah',
  UPDATE: 'Ubah',
  DETAIL: 'Detail',
};

export const STATUS_APPROVAL = {
  PEN: 'PENDING',
  VER: 'VERIFIED',
  APP: 'APPROVED',
  REV: 'REVISE',
  REJ: 'REJECT',
};
