import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { IoArrowUpOutline } from 'react-icons/io5';
import {
  SelectSearch,
  Switch,
  ActionButton,
  DataStatus,
} from '../../../../components';

const HakAksesDashboard = ({ setNavbarTitle }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [jabatanId, setJabatanId] = useState('');
  const [dataJabatan, setDataJabatan] = useState([
    { label: 'Jabatan 01', value: '1' },
    { label: 'Jabatan 02', value: '2' },
    { label: 'Jabatan 03', value: '3' },
  ]);

  useEffect(() => {
    setNavbarTitle('Hak Akses Dashboard');
  }, []);

  const MustSelectDataIndicator = () => (
    <div className="d-flex justify-content-center mt-3">
      <IoArrowUpOutline size="18" />
      <h5 className="ml-2">Pilih data terlebih dahulu</h5>
    </div>
  );

  const DivisiTable = () => {
    return (
      <>
        <div className="bg-white rounded shadow-sm">
          <Table>
            <thead>
              <th>No.</th>
              <th>Kode Hak Akses</th>
              <th>Nama Hak Akses Dashboard</th>
              <th>Aksi</th>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>T01</td>
                <td>Test 01</td>
                <td>
                  <Switch checked={false} />
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        <ActionButton text="Simpan Hak Akses Dashboard" />
      </>
    );
  };

  return (
    <div
      className="p-1"
      style={{
        minHeight: '100vh',
        overflow: 'auto',
      }}
    >
      <SelectSearch
        label="Jabatan"
        placeholder="Pilih jabatan"
        option={dataJabatan}
        onChange={(val) => {
          setIsLoading(true);
          setJabatanId(val.value);

          setTimeout(() => {
            setIsLoading(false);
          }, 1000);
        }}
      />
      {isLoading ? (
        <DataStatus loading={true} text="Memuat..." />
      ) : jabatanId ? (
        <DivisiTable />
      ) : (
        <MustSelectDataIndicator />
      )}
    </div>
  );
};

export default HakAksesDashboard;
