import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { isArray } from 'lodash';
import { CRUDLayout, BackButton, DataStatus } from 'components';
import { PekerjaApi } from 'api';
import {
  SectionDataAkun,
  SectionDataAlamat,
  SectionDataKontak,
  SectionDataPegawai,
  SectionDataPendidikan,
  SectionDataPerbankan,
  SectionDataPribadi,
  SectionDataKeluarga,
} from './Comps';

const PekerjaDetail = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id } = useParams();
  const [dataPekerja, setDataPekerja] = useState({
    loading: true,
    error: false,
    data: {},
  });
  const [alert, setAlert] = useState({
    section: '',
    show: false,
    variant: 'primary',
    text: '',
  });

  const getDataPekerja = () => {
    setDataPekerja({ loading: true, error: false, data: [] });

    PekerjaApi.getSingle(id)
      .then((res) => {
        setDataPekerja({
          loading: false,
          error: false,
          data: res?.data?.data,
        });
      })
      .catch((err) => {
        setDataPekerja({
          loading: false,
          error: true,
          data: [],
        });
      });
  };

  useEffect(() => {
    setNavbarTitle('DETAIL PEKERJA');
    getDataPekerja();
  }, []);

  if (dataPekerja.loading || dataPekerja.error) {
    return (
      <CRUDLayout>
        <CRUDLayout.Head>
          <CRUDLayout.HeadButtonSection>
            <BackButton onClick={() => history.goBack()} />
          </CRUDLayout.HeadButtonSection>
        </CRUDLayout.Head>
        <DataStatus
          loading={dataPekerja.loading}
          text={dataPekerja.loading ? 'Memuat data . . .' : 'Gagal memuat data'}
        />
      </CRUDLayout>
    );
  }

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadButtonSection>
          <BackButton onClick={() => history.goBack()} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      <Row className="mt-3">
        <Col lg>
          {/* Section Data Akun */}
          <Formik
            initialValues={{
              username: dataPekerja?.data?.username,
              password: '',
              password_confirmation: '',
            }}
          >
            <div className="mb-3">
              <SectionDataAkun action="READ" />
            </div>
          </Formik>

          {/* Section Data Pegawai */}
          <Formik
            initialValues={{
              id_karyawan: id,
              no_npwp: dataPekerja?.data?.no_npwp,
              id_jenis_pekerja: dataPekerja?.data?.id_jenis_pekerja,
              id_status_pekerja: dataPekerja?.data?.id_status_pekerja,
              id_status_pekerja_detail:
                dataPekerja?.data?.id_status_pekerja_detail,
              nama_jenis_pekerja: dataPekerja?.data?.nama_jenis_pekerja,
              nama_status_pekerja: dataPekerja?.data?.nama_status_pekerja,
              nama_status_pekerja_detail:
                dataPekerja?.data?.nama_status_pekerja_detail,
              no_karyawan: dataPekerja?.data?.no_karyawan,
              id_jabatan: dataPekerja?.data?.id_jabatan,
              id_lokasi_kerja: dataPekerja?.data?.id_lokasi_kerja,
              nama_jabatan: dataPekerja?.data?.nama_jabatan,
              nama_lokasi_kerja: dataPekerja?.data?.nama_lokasi_kerja,
              tgl_bergabung: dataPekerja?.data?.tgl_bergabung,
              tgl_pengangkatan: dataPekerja?.data?.tgl_pengangkatan,
            }}
            validationSchema={Yup.object().shape({
              id_jenis_pekerja: Yup.string().required('Pilih Jenis Pegawai'),
              id_status_pekerja: Yup.string().required('Pilih Status Pegawai'),
              id_jabatan: Yup.string().required('Pilih jabatan'),
              tgl_bergabung: Yup.string().required('Pilih tanggal bergabung'),
              tgl_pengangkatan: Yup.string().required(
                'Pilih tanggal pengangkatan'
              ),
              id_lokasi_kerja: Yup.string().required('Pilih Lokasi Kerja'),
            })}
            onSubmit={(values, { setSubmitting }) =>
              PekerjaApi.updateDataKepegawaian(values)
                .then(() =>
                  setAlert({
                    section: 'kepegawaian',
                    show: true,
                    variant: 'primary',
                    text: 'Data kepegawaian berhasil diubah!',
                  })
                )
                .catch((err) =>
                  setAlert({
                    section: 'kepegawaian',
                    show: true,
                    variant: 'danger',
                    text: isArray(err?.response?.data?.error)
                      ? err?.response?.data?.error?.map((val) => <li>{val}</li>)
                      : err?.response?.data?.message,
                  })
                )
                .finally(() => setSubmitting(false))
            }
          >
            {({ isSubmitting, handleSubmit }) => (
              <div className="mb-3">
                <SectionDataPegawai
                  action="READ"
                  submitLoading={isSubmitting}
                  onSubmit={handleSubmit}
                  alert={alert?.section === 'kepegawaian' && alert}
                />
              </div>
            )}
          </Formik>

          {/* Section Data Kontak */}
          <Formik
            initialValues={{
              id_karyawan: id,
              no_telp: dataPekerja?.data?.no_telp,
              no_hp: dataPekerja?.data?.no_hp,
              email: dataPekerja?.data?.email,
              kontak_darurat: dataPekerja?.data?.kontak_darurat,
              keterangan_kontak_darurat:
                dataPekerja?.data?.keterangan_kontak_darurat,
            }}
            validationSchema={Yup.object().shape({
              no_telp: Yup.number()
                .required('Masukan nomor telephone')
                .typeError('Nomor telephone harus berupa angka')
                .test({
                  test: (val) => val?.toString().length >= 10,
                  message: 'Panjang no. telp minimal 10 karakter',
                })
                .test({
                  test: (val) => val?.toString().length <= 13,
                  message: 'Panjang no. telp maksimal 13 karakter',
                })
                .nullable(),
              no_hp: Yup.number()
                .typeError('Nomor handphone harus berupa angka')
                .test({
                  test: (val) => (val ? val?.toString().length >= 10 : true),
                  message: 'Panjang no. handphone minimal 10 karakter',
                })
                .test({
                  test: (val) => (val ? val?.toString().length <= 13 : true),
                  message: 'Panjang no. handphone maksimal 13 karakter',
                })
                .nullable(),
              email: Yup.string().email('Format email tidak valid').nullable(),
              kontak_darurat: Yup.string()
                .required('Masukan kontak darurat')
                .nullable(),
            })}
            onSubmit={(values, { setSubmitting }) =>
              PekerjaApi.updateDataKontak(values)
                .then(() =>
                  setAlert({
                    section: 'kontak',
                    show: true,
                    variant: 'primary',
                    text: 'Data kontak berhasil diubah!',
                  })
                )
                .catch((err) =>
                  setAlert({
                    section: 'kontak',
                    show: true,
                    variant: 'danger',
                    text: isArray(err?.response?.data?.error)
                      ? err?.response?.data?.error?.map((val) => <li>{val}</li>)
                      : err?.response?.data?.message,
                  })
                )
                .finally(() => setSubmitting(false))
            }
          >
            {({ isSubmitting, handleSubmit }) => (
              <div className="mb-3">
                <SectionDataKontak
                  action="READ"
                  submitLoading={isSubmitting}
                  onSubmit={handleSubmit}
                  alert={alert?.section === 'kontak' && alert}
                />
              </div>
            )}
          </Formik>

          {/* Section Data Pendidikan */}
          <Formik
            initialValues={{
              id_karyawan: id,
              id_jenjang_pendidikan: dataPekerja?.data?.id_jenjang_pendidikan,
              nama_jenjang_pendidikan:
                dataPekerja?.data?.nama_jenjang_pendidikan,
              lembaga_pendidikan: dataPekerja?.data?.lembaga_pendidikan,
              tahun_selesai: dataPekerja?.data?.tahun_selesai,
            }}
            validationSchema={Yup.object().shape({})}
            onSubmit={(values, { setSubmitting }) =>
              PekerjaApi.updateDataPendidikan(values)
                .then(() =>
                  setAlert({
                    section: 'pendidikan',
                    show: true,
                    variant: 'primary',
                    text: 'Data pendidikan berhasil diubah!',
                  })
                )
                .catch((err) =>
                  setAlert({
                    section: 'pendidikan',
                    show: true,
                    variant: 'danger',
                    text: isArray(err?.response?.data?.error)
                      ? err?.response?.data?.error?.map((val) => <li>{val}</li>)
                      : err?.response?.data?.message,
                  })
                )
                .finally(() => setSubmitting(false))
            }
          >
            {({ isSubmitting, handleSubmit }) => (
              <div className="mb-3">
                <SectionDataPendidikan
                  action="READ"
                  submitLoading={isSubmitting}
                  onSubmit={handleSubmit}
                  alert={alert?.section === 'pendidikan' && alert}
                />
              </div>
            )}
          </Formik>
        </Col>

        <Col lg>
          {/* Section Data Kontak */}
          <Formik
            initialValues={{
              id_karyawan: dataPekerja?.data?.id_karyawan,
              nama_karyawan: dataPekerja?.data?.nama_karyawan,
              no_ktp: dataPekerja?.data?.no_ktp,
              tempat_lahir: dataPekerja?.data?.tempat_lahir,
              tgl_lahir: dataPekerja?.data?.tgl_lahir,
              jenis_kelamin: dataPekerja?.data?.jenis_kelamin,
              agama: dataPekerja?.data?.agama,
              status_perkawinan: dataPekerja?.data?.status_perkawinan,
              id_jabatan: dataPekerja?.data?.id_jabatan,
            }}
            validationSchema={Yup.object().shape({
              nama_karyawan: Yup.string()
                .required('Masukan nama Pegawai')
                .nullable(),
              no_ktp: Yup.string()
                .required('Masukan nomor ktp')
                .test({
                  test: (val) => val?.toString().length === 16,
                  message: 'Panjang KTP harus 16 karakter',
                })
                .nullable(),
              tempat_lahir: Yup.string()
                .required('Masukan tempat lahir')
                .nullable(),
              tgl_lahir: Yup.string()
                .required('Pilih tanggal lahir')
                .nullable(),
              jenis_kelamin: Yup.string()
                .required('Pilih jenis kelamin')
                .nullable(),
              agama: Yup.string().required('Pilih agama').nullable(),
              status_perkawinan: Yup.string()
                .required('Pilih status perkawinan')
                .nullable(),
            })}
            onSubmit={(values, { setSubmitting }) =>
              PekerjaApi.updateDataPribadi(values)
                .then(() =>
                  setAlert({
                    section: 'pribadi',
                    show: true,
                    variant: 'primary',
                    text: 'Data pribadi berhasil diubah!',
                  })
                )
                .catch((err) =>
                  setAlert({
                    section: 'pribadi',
                    show: true,
                    variant: 'danger',
                    text: isArray(err?.response?.data?.error)
                      ? err?.response?.data?.error?.map((val) => <li>{val}</li>)
                      : err?.response?.data?.message,
                  })
                )
                .finally(() => setSubmitting(false))
            }
          >
            {({ isSubmitting, handleSubmit }) => (
              <div className="mb-3">
                <SectionDataPribadi
                  action="READ"
                  submitLoading={isSubmitting}
                  onSubmit={handleSubmit}
                  alert={alert?.section === 'pribadi' && alert}
                />
              </div>
            )}
          </Formik>

          {/* Section Data Alamat */}
          <Formik
            initialValues={{
              id_karyawan: dataPekerja?.data?.id_karyawan,
              id_provinsi_asal: dataPekerja?.data?.id_provinsi_asal,
              id_kabupaten_asal: dataPekerja?.data?.id_kabupaten_asal,
              id_kecamatan_asal: dataPekerja?.data?.id_kecamatan_asal,
              id_desa_asal: dataPekerja?.data?.id_desa_asal,
              nama_provinsi_asal: dataPekerja?.data?.nama_provinsi_asal,
              nama_kabupaten_asal: dataPekerja?.data?.nama_kabupaten_asal,
              nama_kecamatan_asal: dataPekerja?.data?.nama_kecamatan_asal,
              nama_desa_asal: dataPekerja?.data?.nama_desa_asal,
              alamat_asal: dataPekerja?.data?.alamat_asal,
              kode_pos_asal: dataPekerja?.data?.kode_pos_asal,
              id_provinsi_saat_ini: dataPekerja?.data?.id_provinsi_saat_ini,
              id_kabupaten_saat_ini: dataPekerja?.data?.id_kabupaten_saat_ini,
              id_kecamatan_saat_ini: dataPekerja?.data?.id_kecamatan_saat_ini,
              id_desa_saat_ini: dataPekerja?.data?.id_desa_saat_ini,
              nama_provinsi_saat_ini: dataPekerja?.data?.nama_provinsi_saat_ini,
              nama_kabupaten_saat_ini:
                dataPekerja?.data?.nama_kabupaten_saat_ini,
              nama_kecamatan_saat_ini:
                dataPekerja?.data?.nama_kecamatan_saat_ini,
              nama_desa_saat_ini: dataPekerja?.data?.nama_desa_saat_ini,
              alamat_saat_ini: dataPekerja?.data?.alamat_saat_ini,
              kode_pos_saat_ini: dataPekerja?.data?.kode_pos_saat_ini,
            }}
            validationSchema={Yup.object().shape({
              id_provinsi_asal: Yup.string()
                .required('Pilih provinsi asal')
                .nullable(),
              id_kabupaten_asal: Yup.string()
                .required('Pilih kabupaten asal')
                .nullable(),
              id_kecamatan_asal: Yup.string()
                .required('Pilih kecamatan asal')
                .nullable(),
              id_desa_asal: Yup.string().required('Pilih desa asal').nullable(),
              alamat_asal: Yup.string()
                .required('Masukan alamat asal')
                .nullable(),
              kode_pos_asal: Yup.number()
                .required('Masukan kode pos asal')
                .typeError('Kode pos harus nomor')
                .test({
                  test: (val) => val?.toString().length === 5,
                  message: 'Kode pos asal harus 5 karakter',
                })
                .nullable(),
              id_provinsi_saat_ini: Yup.string()
                .required('Pilih provinsi saat ini')
                .nullable(),
              id_kabupaten_saat_ini: Yup.string()
                .required('Pilih kabupaten saat ini')
                .nullable(),
              id_kecamatan_saat_ini: Yup.string()
                .required('Pilih kecamatan saat ini')
                .nullable(),
              id_desa_saat_ini: Yup.string()
                .required('Pilih desa saat ini')
                .nullable(),
              alamat_saat_ini: Yup.string()
                .required('Masukan alamat saat ini')
                .nullable(),
              kode_pos_saat_ini: Yup.string()
                .typeError('Kode pos harus nomor')
                .required('Masukan kode pos saat ini')
                .test({
                  test: (val) => val?.toString().length === 5,
                  message: 'Kode pos saat ini harus 5 karakter',
                })
                .nullable(),
            })}
            onSubmit={(values, { setSubmitting }) =>
              PekerjaApi.updateDataAlamat(values)
                .then(() =>
                  setAlert({
                    section: 'alamat',
                    show: true,
                    variant: 'primary',
                    text: 'Data alamat berhasil diubah!',
                  })
                )
                .catch((err) =>
                  setAlert({
                    section: 'alamat',
                    show: true,
                    variant: 'danger',
                    text: isArray(err?.response?.data?.error)
                      ? err?.response?.data?.error?.map((val) => <li>{val}</li>)
                      : err?.response?.data?.message,
                  })
                )
                .finally(() => setSubmitting(false))
            }
          >
            {({ isSubmitting, handleSubmit }) => (
              <div className="mb-3">
                <SectionDataAlamat
                  action="READ"
                  submitLoading={isSubmitting}
                  onSubmit={handleSubmit}
                  alert={alert?.section === 'alamat' && alert}
                />
              </div>
            )}
          </Formik>

          {/* Section Data Bank */}
          <Formik
            initialValues={{
              id_karyawan: dataPekerja?.data?.id_karyawan,
              kode_bank: dataPekerja?.data?.kode_bank,
              nama_bank: dataPekerja?.data?.nama_bank,
              no_rekening: dataPekerja?.data?.no_rekening,
              atas_nama: dataPekerja?.data?.atas_nama,
              cabang: dataPekerja?.data?.cabang,
            }}
            validationSchema={Yup.object().shape({
              kode_bank: Yup.string().required('Pilih bank').nullable(),
              no_rekening: Yup.string()
                .required('Masukan nomor rekening')
                .nullable(),
              atas_nama: Yup.string()
                .required('Masukan atas nama rekening')
                .nullable(),
            })}
            onSubmit={(values, { setSubmitting }) =>
              PekerjaApi.updateDataBank(values)
                .then(() =>
                  setAlert({
                    section: 'bank',
                    show: true,
                    variant: 'primary',
                    text: 'Data bank berhasil diubah!',
                  })
                )
                .catch((err) =>
                  setAlert({
                    section: 'bank',
                    show: true,
                    variant: 'danger',
                    text: isArray(err?.response?.data?.error)
                      ? err?.response?.data?.error?.map((val) => <li>{val}</li>)
                      : err?.response?.data?.message,
                  })
                )
                .finally(() => setSubmitting(false))
            }
          >
            {({ isSubmitting, handleSubmit }) => (
              <div className="mb-3">
                <SectionDataPerbankan
                  action="READ"
                  submitLoading={isSubmitting}
                  onSubmit={handleSubmit}
                  alert={alert?.section === 'bank' && alert}
                />
              </div>
            )}
          </Formik>
        </Col>
      </Row>

      {/* Section Data Keluarga */}
      <div>
        <SectionDataKeluarga id={id} action="READ" />
      </div>
    </CRUDLayout>
  );
};

export default PekerjaDetail;
