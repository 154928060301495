import Services from '../../../services';

class PangkatApi {
  get() {
    return Services.get('/hrda/pangkat');
  }

  getPage(page, dataLength, key) {
    return Services.get(
      `/hrda/pangkat/page/?per_page=${dataLength}&page=${page}&q=${key}`
    );
  }

  getDropdown() {
    return Services.get('/hrda/pangkat/dropdown');
  }

  search(key) {
    return Services.get('/hrda/pangkat/page/?per_page=10&page=1&q=' + key);
  }

  show(value) {
    return Services.put('/hrda/pangkat/show', value);
  }

  hide(value) {
    return Services.put('/hrda/pangkat/hide', value);
  }

  create(value) {
    return Services.post('/hrda/pangkat', value);
  }

  update(value) {
    return Services.put('/hrda/pangkat', value);
  }

  delete(value) {
    return Services.post('/hrda/pangkat/delete', value);
  }
}

export default new PangkatApi();
